import React, {useCallback, useContext, useEffect, useState} from 'react'
import {useHttp} from '../hooks/http.hook'
import {AuthContext} from '../context/AuthContext'
import config from '../config.json'
import {ModalWin} from "../elements/ModalWin"
import {LoaderClass} from "../elements/LoaderClass"
import {objectLang, setErrorWindow} from "../functions"
import {MoneySettingsClass} from "../components/MoneySettingsClass"

export const MoneySettingsPage = () => {
    localStorage.setItem('page', 'MoneySettingsPage')
    const lang = objectLang().language
    const auth = useContext(AuthContext)
    const {loading, request} = useHttp()
    const [allowed, setAllowed] = useState(true)
    const [dataModal, setDataModal] = useState({})
    const [settingData, setSettingData] = useState({})

    const data = useCallback(async () => {
        let host
        window.location.host === 'localhost:3000' ? host = config.localHost : host = config.heroku
        try {
            const data = await request(
                `${host}/api/money/settings/get`,
                'GET',
                null,
                {
                    Authorization: `Bearer ${auth.accessToken} RefreshToken ${auth.refreshToken} DeviceId ${auth.deviceId}`
                })
            if (data.status !== 200) {
                setAllowed(false)
                setDataModal(setErrorWindow('', data.code, '', '', 'replaceTo /money/main'))
            } else {
                setSettingData(data.settings)
            }

        } catch (e) {
            window.location.replace('/money/main')
        }
    }, [request, auth])

    useEffect(() => {
        data().then()
    }, [data])

    if (!allowed) {
        return (
            <ModalWin data={dataModal}/>
        )
    }

    if (loading) {
        return <LoaderClass/>
    }

    return (
        <>
            <MoneySettingsClass
            lang={lang}
            data={settingData}
            />
        </>
    )
}