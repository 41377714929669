import React, {useCallback, useContext, useEffect, useState} from 'react'
import {AuthContext} from "../context/AuthContext"
import config from "../config.json"
import {useHttp} from "../hooks/http.hook"
import {useParams} from "react-router-dom"
import {NoteViewCardClass} from "../components/NoteViewCardClass"
import {objectLang, removeCookieOfFolderId, setErrorWindow} from "../functions"
import {ModalWin} from "../elements/ModalWin"
import {LoaderClass} from "../elements/LoaderClass"
import {ViewWindowQuestion} from "../elements/ViewWindowQuestion"

export const NotePage = ({noteObject = null}) => {
    localStorage.setItem('page', 'NotePage')
    const auth = useContext(AuthContext)
    const {request, loading} = useHttp()
    const [note, setNote] = useState({})
    const [newNote, setNewNote] = useState({})
    const [allowed, setAllowed] = useState(true)
    const [dataModal, setDataModal] = useState({})
    const [isQuestion, setIsQuestion] = useState(true)
    const noteId = useParams().id

    const lang = objectLang().language

    const getNote = useCallback(async () => {
        let host
        window.location.host === 'localhost:3000' ? host = config.localHost : host = config.heroku
        try {
            const data = await request(
                `${host}/api/note/view/${noteId}`,
                'GET',
                null,
                {
                    Authorization: `Bearer ${auth.accessToken} RefreshToken ${auth.refreshToken} DeviceId ${auth.deviceId}`
                })
            if (data.status > 200) {
                setAllowed(false)
                setDataModal(setErrorWindow('', data.code))
            } else {
                setNote(data.note)
                localStorage.setItem('note-DB', JSON.stringify(data.note))
            }

        } catch (e) {
            window.location.replace("/main")
        }
    }, [auth, request, noteId])

    useEffect(() => {
        if (!noteObject) {
            getNote().then()
        } else {
            const newNote = JSON.parse(localStorage.getItem('note-new'))
            setNote(newNote)
        }
    }, [getNote, noteObject])

    if (auth.isAuthenticated) {
        localStorage.setItem('pathBack', `/folder/open/${note.parentFolder}`)
    }
    if (note.owner === '64648579d6767031d41bd359') {
        localStorage.setItem('pathBack', `/folder/open-public/${note.parentFolder}`)
    }

    const saveNoteHandler = async () => {
        let host
        window.location.host === 'localhost:3000' ? host = config.localHost : host = config.heroku
        try {
            const data = await request(
                `${host}/api/note/save/${note["_id"]}`,
                'POST',
                {
                    userId: auth.userId,
                    name: document.querySelector('#name').value,
                    body: document.querySelector('#body-note').value,
                    parentFolder: note.parentFolder
                },
                {
                    Authorization: `Bearer ${auth.accessToken} RefreshToken ${auth.refreshToken} DeviceId ${auth.deviceId}`
                })
            if (data.status > 200) {
                setAllowed(false)
                setDataModal(setErrorWindow('', data.code))
            } else {
                removeCookieOfFolderId(note.parentFolder)
                window.location.reload()
            }

        } catch (e) {
            window.location.replace("/main")
        }
    }

    const closeNoteHandler = (oldNote, updatedNote) => {
        setNewNote(updatedNote)
        if ((oldNote.body !== updatedNote.body) || ((oldNote.name !== updatedNote.name))) {
            localStorage.setItem('note-new', JSON.stringify(updatedNote))
            setIsQuestion(false)
            setDataModal(
                setErrorWindow(
                    lang.body.notePage.windowRequestIfSave.titleUpdate,
                    '',
                    lang.body.notePage.windowRequestIfSave.descriptionUpdate,
                    lang.body.notePage.windowRequestIfSave.btnOKUpdate,
                    'saveNote',
                    lang.body.notePage.windowRequestIfSave.btnCancelUpdate,
                    'replaceToBack',
                    'success',
                    'danger'))
        } else {
            window.location.replace(localStorage.getItem('pathBack'))
        }
    }

    if (loading) {
        return <LoaderClass/>
    }

    if (!allowed) {
        return (
            <>
                <ModalWin data={dataModal}/>
            </>
        )
    }

    if (!isQuestion) {
        return (
            <>
                {<NotePage noteObject={JSON.parse(localStorage.getItem('note-new'))}/>}
                {<ViewWindowQuestion newNote={newNote} data={dataModal}/>}
            </>
        )
    }

    return (
        <>
            {<NoteViewCardClass
                auth={auth}
                note={note}
                saveNoteHandler={saveNoteHandler}
                closeNoteHandler={closeNoteHandler}
            />}
        </>
    )
}

export class saveHandler {
}