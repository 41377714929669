import React from 'react'
import {Link, NavLink} from 'react-router-dom'
import {objectLang} from "../functions"
import $ from "jquery"

export class NavbarClass extends React.Component {

    render() {

        const {auth, avatarLink, currentLang, setResponseSearch, changeSelect} = {
            auth: this.props.auth,
            avatarLink: this.props.avatarLink,
            currentLang: this.props.currentLang,
            setResponseSearch: this.props.setResponseSearch,
            changeSelect: this.props.changeSelect,
        }

        let setValueLang
        if (currentLang) {
            setValueLang = currentLang.lang
        } else {
            setValueLang = 'en'
        }
        const lng = objectLang().languages

        const languages = []
        for (const i in lng) {
            languages.push([i, lng[i].navbar.optionalsLanguage])
        }

        const navbarNavClick = () => {
            const toggle = $('#navbar-nav')
            $('.navbar-nav').removeClass('ms-5')
            if (toggle.hasClass('show')) {
                toggle.removeClass('show')
            } else {
                toggle.addClass('show')
            }
        }

        return (
            <>
                <div className={"sticky-top bg-body-tertiary navbar-expand-lg"}>

                    <nav className={"navbar p-0 pt-1"} id={"navbar"}>
                        <div className={"container-fluid p-2 flex-lg-nowrap overflow-auto col col-12"}>

                            <div className={'d-flex flex-nowrap col col-xl-2 col-sm-2 col-md-4'}>
                                {/*Авторизованный аватар*/}
                                {auth.isAuthenticated &&
                                <div>
                                    <div style={{backgroundImage: `url(${avatarLink})`}}
                                         className={"avatar-auth border rounded-circle m-1 mb-xl-0"}>
                                    </div>
                                </div>}

                                {/*Неавторизованный аватар*/}
                                {!auth.isAuthenticated &&
                                <div>
                                    <div className={"avatar-not-auth border rounded-circle m-1 mb-xl-0"}>

                                    </div>
                                </div>}

                                {/*Никнейм*/}
                                <NavLink to={"/main"}
                                         className={"d-flex justify-content-center align-items-center nav-name text-decoration-none text-success inactive ps-2"}>
                                    <div className={"overflow-hidden text-center d-flex"}>

                                        {/*Авторизованный Никнейм*/}
                                        {auth.isAuthenticated &&
                                        <h5 id={"navUserName"}
                                            className={"d-flex justify-content-center align-items-center"}>
                                            {currentLang.navbar.isAuth.title} {auth.nickName}</h5>}

                                        {/*Неавторизованный Никнейм*/}
                                        {!auth.isAuthenticated &&
                                        <h5 id={"navTitle"} className={"d-flex justify-content-center"}>
                                            {currentLang.navbar.isNotAuth.title}
                                        </h5>}
                                    </div>
                                </NavLink>
                            </div>

                            {auth.isAuthenticated && <div>
                                <div className={"overflow-hidden text-center mb-1 pe-2"}>
                                    {/*Кнопка поиска*/}
                                    <button id={"navBtnSearch"}
                                            className={"btn btn-outline-primary border-0 fw-bold fs-5"}
                                            type={"button"}
                                            onClick={() => {
                                                setResponseSearch(true)
                                            }}>
                                        {currentLang.navbar.isAuth.btnSearch}
                                    </button>
                                </div>
                            </div>}

                            {/*Кнопка развертывания меню в мобильнике*/}
                            <button className={"navbar-toggler"}
                                    type={"button"}
                                    onClick={navbarNavClick}
                                    data-bs-toggle={"collapse"}
                                    data-bs-target={"navbar-nav"}
                                    aria-controls={"navbar-nav"}
                                    aria-expanded={"false"}
                                    aria-label={"Toggle navigation"}>
                                <span className={"navbar-toggler-icon"}/>
                            </button>

                            {/*Мобильное меню*/}
                            <div className={"container collapse navbar-collapse mt-2 mb-2 justify-content-between"}
                                 id={"navbar-nav"}>

                                {/*Само меню*/}
                                <ul className={"navbar-nav ms-5 me-auto mb-lg-0"}>

                                    {/*Селектор языков*/}
                                    {<li id={"navSelLang"} className={"nav-item ps-2"}>
                                        <select
                                            className={"form-select w-auto"}
                                            name={"language"}
                                            id={"language"}
                                            onChange={changeSelect}
                                            defaultValue={setValueLang}
                                        >{languages.map((lang, index) => {
                                                return (
                                                    <option
                                                        value={lang[0]}
                                                        key={index}
                                                    >{lang[1][lang[0]]}
                                                    </option>
                                                )
                                            }
                                        )}
                                        </select>
                                    </li>}

                                    {/*Главная страница*/}
                                    {<li id={"navToHome"} className={"nav-item ps-2"}>
                                        <NavLink
                                            to={currentLang.navbar.linkToHome}
                                            className={"nav-link inactive rounded-3"}
                                            onClick={navbarNavClick}>
                                            {currentLang.navbar.toHome}
                                        </NavLink>
                                    </li>}

                                    {/*Страница авторизации*/}
                                    {!auth.isAuthenticated && <li id={"navBtnAuth"} className={"nav-item ps-2"}>
                                        <NavLink
                                            to={currentLang.navbar.isNotAuth.linkAuth}
                                            className={"nav-link inactive rounded-3"}>
                                            {currentLang.navbar.isNotAuth.btnAuth}
                                        </NavLink>
                                    </li>}

                                    {/*Страница регистрации*/}
                                    {!auth.isAuthenticated && <li id={"navBtnRegister"} className={"nav-item ps-2"}>
                                        <NavLink
                                            to={currentLang.navbar.isNotAuth.linkRegister}
                                            onClick={navbarNavClick}
                                            className={"nav-link inactive rounded-3"}>
                                            {currentLang.navbar.isNotAuth.btnRegister}
                                        </NavLink>
                                    </li>}

                                    {/*Страница "Мои линки"*/}
                                    {auth.isAuthenticated && <li id={"navBtnMyLinks"} className={"nav-item ps-2"}>
                                        <NavLink
                                            to={`/folder/open/${auth.rootFolder}`}
                                            className={"nav-link inactive rounded-3"}
                                            aria-current={"page"}>
                                            {currentLang.navbar.isAuth.btnMyLinks}
                                        </NavLink>
                                    </li>}

                                    {/*Страница "Средства"*/}
                                    {auth.isAuthenticated && <li id={"navBtnMoney"} className={"nav-item ps-2"}>
                                        <NavLink
                                            to={`/money/main`}
                                            className={"nav-link inactive rounded-3"}
                                            aria-current={"page"}>
                                            {currentLang.navbar.isAuth.btnMoney}
                                        </NavLink>
                                    </li>}

                                    {/*Страница Публичные линки*/}
                                    {currentLang.lang === 'ru' && <li id={"navBtnPublic"} className={"nav-item ps-2"}>
                                        <NavLink
                                            to={currentLang.navbar.isNotAuth.linkPublicLinks}
                                            onClick={navbarNavClick}
                                            className={"nav-link inactive rounded-3"}
                                            aria-current={"page"}>
                                            {currentLang.navbar.isNotAuth.btnPublicLinks}
                                        </NavLink>
                                    </li>}

                                    {/*Страница "Профиль"*/}
                                    {auth.isAuthenticated && <li id={"navBtnProfile"} className={"nav-item ps-2"}>
                                        <NavLink
                                            to={currentLang.navbar.isAuth.linkProfile}
                                            className={"nav-link inactive rounded-3"}>
                                            {currentLang.navbar.isAuth.btnProfile}
                                        </NavLink>
                                    </li>}

                                    {/*Обновление перевода сайта"*/}
                                    {auth.isAuthenticated && auth.userId === '64106e6caf210c0014ff6195' &&
                                    <li id={"navBtnUpdateLang"} className={"nav-item ps-2"}>
                                        <NavLink
                                            to={currentLang.navbar.isAuth.linkUpdateLang}
                                            className={"nav-link inactive rounded-3"}>
                                            {currentLang.navbar.isAuth.btnUpdateLang}
                                        </NavLink>
                                    </li>}

                                    {/*Выход из профиля"*/}
                                    {auth.isAuthenticated && <li id={"navBtnOut"} className={"nav-item ps-2"}>
                                        <Link to={'/exit'}
                                              className={"nav-link inactive rounded-3 text-danger"}>
                                            {currentLang.navbar.isAuth.btnOut}
                                        </Link>
                                    </li>}

                                </ul>

                            </div>

                        </div>
                    </nav>
                </div>
            </>
        )
    }
}
