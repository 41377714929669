import React, {useEffect, useState} from 'react'
import {useHttp} from '../hooks/http.hook'
import {eyeHandler, objectLang, setErrorWindow, validationPassword} from "../functions"
import config from '../config.json'
import {Link} from "react-router-dom"
import {ModalWin} from "../elements/ModalWin"
import {LoaderClass} from "../elements/LoaderClass"
const $ = require("jquery")

export const RegisterPage = () => {
    localStorage.setItem('page', 'RegisterPage')
    let lang = objectLang().language
    const {loading, request} = useHttp()
    const [language, setLanguage] = useState('en')
    const [email, setEmail] = useState('')
    const [firstName, setFirstName] = useState('')
    let passOne = ''
    let passTwo = ''
    const [allowed, setAllowed] = useState(true)
    const [dataModal, setDataModal] = useState({})

    useEffect(() => {
        window.M.updateTextFields()
    })

    const langHandler = event => {
        setLanguage(event.target.value)
    }
    const emailHandler = event => {
        setEmail(event.target.value)
    }
    const firstNameHandler = event => {
        setFirstName(event.target.value)
    }
    const passHandlerOne = event => {
        passOne = event.target.value
    }
    const passHandlerTwo = event => {
        passTwo = event.target.value
    }

    const registerHandler = async () => {
        lang = objectLang().language
        let host
        window.location.host === 'localhost:3000' ? host = config.localHost : host = config.heroku
        passOne = document.querySelector('#passOne').value
        passTwo = document.querySelector('#passTwo').value
        if (!email || !firstName) {
            setAllowed(false)
            const modal = {
                title: lang.modal.errors.title,
                desc: lang.messages.fillToForm,
                btnCancel: lang.modal.errors.btnCancel,
                btnOK: lang.modal.errors.btnOK,
            }
            setDataModal(modal)
            return
        }

        const validPasswords = validationPassword(passOne, passTwo, true)
        if (!validPasswords.flag) {
            setAllowed(false)
            const modal = {
                title: lang.modal.errors.title,
                desc: lang.messages[`code_${validPasswords.code}`],
                btnCancel: lang.modal.errors.btnCancel,
                btnOK: lang.modal.errors.btnOK,
            }
            setDataModal(modal)
            return
        }
        const password = validPasswords.res

        try {
            const data = await request(
                `${host}/api/user/register/`,
                'POST',
                {
                    email: btoa(email),
                    password: btoa(password),
                    firstName: firstName,
                    language: language,
                })

            if (data.status === 201) {
                return window.location.replace(`/verify/?uid=${data.userId}`)
            } else {
                setAllowed(false)
                setDataModal(setErrorWindow(
                    lang.modal.errors.title,
                    data.code,
                    lang.messages[`code_${data.code}`],
                    lang.modal.errors.btnOK,
                    'replaceToAuth',
                    lang.modal.errors.btnCancel,
                    'replaceToMain'
                ))
            }
        } catch (e) {
            window.location.replace("/main")
        }
    }

    const registerGoogleHandler = async () => {
        // console.log(email)
    }

    if (loading) {
        return <LoaderClass />
    }

    if (!allowed) {
        return <ModalWin data={dataModal}/>
    }

    return (
        <>
            <div className={'container col-sm-9 col-xl-6 col-xxl-4'}>
                <form className={'rounded'}>
                    <fieldset className={'teal m-3 p-3'}>
                        <div className={"d-flex"}>
                            <legend id={"legend"} className={"fs-1"}>{lang.body.registerPage.title}</legend>
                            <button // Отключено
                                id={'register-google'}
                                type={'button'}
                                onClick={registerGoogleHandler}
                                disabled={loading}
                                className={"col col-6 btn btn-light fs-4 d-none"}>
                                {lang.body.registerPage.btnRegisterGoogle}
                            </button>
                        </div>
                        <div className={"mb-3"} id={"divEmail"}>
                            <label
                                id={"label-email"}
                                htmlFor={"email"}
                                className={"form-label"}>
                                {lang.body.registerPage.labelEmail}
                            </label>
                            <input
                                required
                                type={"text"}
                                name={"email"}
                                id={"email"}
                                onChange={emailHandler}
                                className={"form-control"}
                                placeholder={lang.body.registerPage.placeholderEmail}
                            />
                        </div>
                        <div className={"mb-3"} id={"divFirstName"}>
                            <label
                                id={"label-firstname"}
                                htmlFor={"firstName"}
                                className={"form-label"}>
                                {lang.body.registerPage.labelFirstName}
                            </label>
                            <input
                                required
                                type={"text"}
                                name={"firstName"}
                                id={"firstName"}
                                onChange={firstNameHandler}
                                className={"form-control"}
                                placeholder={lang.body.registerPage.placeholderFirstName}
                            />
                        </div>
                        <div className={"mb-3"} id={"divLanguage"}>
                            <label
                                id={"label-language"}
                                htmlFor={"language"}
                                className={"form-label"}>
                                {lang.body.registerPage.labelLanguage}
                            </label>
                            <select
                                name={"language"}
                                id={"languageReg"}
                                className={"form-control"}
                                onChange={langHandler}>
                                <option value={"en"}>English</option>
                                <option value={"ru"}>Русский</option>
                                <option value={"he"}>עברית</option>
                                <option value={"ge"}>Deutsch</option>
                                <option value={"ar"}>عربي</option>
                            </select>
                        </div>
                        <div className={"mb-3"} id={"divPassOne"}>
                            <label
                                id={"label-password-one"}
                                htmlFor={"passOne"}
                                className={"form-label"}>
                                {lang.body.registerPage.labelPasswordOne}
                            </label>
                            <div className={"input-group"}>
                                <input
                                    autoComplete={"off"}
                                    autoFocus={false}
                                    required
                                    type={"password"}
                                    name={"passOne"}
                                    id={"passOne"}
                                    onChange={passHandlerOne}
                                    className={"form-control"}
                                    placeholder={lang.body.registerPage.placeholderPasswordOne}
                                />
                                <div className={"input-group-prepend"}>
                                    <div className={"div-eye input-group-text"}>
                                        <Link to={``}
                                              className={"eye-register"}
                                              onClick={() => {
                                                  eyeHandler($('#eye-open-close-one'), $('#passOne'))
                                              }}>
                                            <i id={"eye-open-close-one"} className={"fa-regular fa-eye-slash"}/>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"mb-3"} id={"divPassTwo"}>
                            <label
                                id={"label-password-two"}
                                htmlFor={"passTwo"}
                                className={"form-label"}>
                                {lang.body.registerPage.labelPasswordTwo}
                            </label>
                            <div className={"input-group"}>
                                <input
                                    autoComplete={"off"}
                                    autoFocus={false}
                                    required
                                    type={"password"}
                                    name={"passTwo"}
                                    id={"passTwo"}
                                    onChange={passHandlerTwo}
                                    className={"form-control"}
                                    placeholder={lang.body.registerPage.placeholderPasswordTwo}
                                />
                                <div className={"input-group-prepend"}>
                                    <div className={"div-eye input-group-text"}>
                                        <Link to={``}
                                              className={"eye-register"}
                                              onClick={() => {
                                                  eyeHandler($('#eye-open-close-two'), $('#passTwo'))
                                              }}>
                                            <i id={"eye-open-close-two"} className={"fa-regular fa-eye-slash"}/>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button
                            id={'register'}
                            type={'button'}
                            onClick={registerHandler}
                            disabled={loading}
                            className={"btn btn-primary mt-4"}>
                            {lang.body.registerPage.btnRegister}
                        </button>
                    </fieldset>
                </form>
            </div>
        </>
    )
}
