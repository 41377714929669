import React, {useCallback, useContext, useEffect, useState} from 'react'
import {useHttp} from '../hooks/http.hook'
import {AuthContext} from '../context/AuthContext'
import config from '../config.json'
import {LoaderClass} from "./LoaderClass"
import {setErrorWindow} from "../functions"
import {ModalWin} from "./ModalWin"

export const UpdateLanguages = () => {
    const {request, loading} = useHttp()
    const auth = useContext(AuthContext)
    const [allowed, setAllowed] = useState(true)
    const [dataModal, setDataModal] = useState({})

    const fetch = useCallback(async () => {
        let host
        window.location.host === 'localhost:3000' ? host = config.localHost : host = config.heroku
        try {
            // Обновляем базу языков из кода на сервере
            const data = await request(
                `${host}/api/language/load`,
                'POST',
                {
                    en: 'en',
                    ru: 'ru',
                    he: 'he',
                    ge: 'ge',
                    ar: 'ar'
                },
                {
                    Authorization: `Bearer ${auth.accessToken} RefreshToken ${auth.refreshToken} DeviceId ${auth.deviceId}`
                })

            if (data.status !== 200) {
                setAllowed(false)
                setDataModal(setErrorWindow('', data.code, '', '', 'replaceTo /main'))
            } else {
                window.location.replace('/main')
            }

        } catch (e) {
            console.error(e.message)
        }
    }, [request, auth])

    useEffect(() => {
        fetch().then()
    }, [fetch])

    if (loading) {
        return <LoaderClass />
    }

    if (!allowed) {
        return (<><ModalWin data={dataModal}/></>)
    } else {
        return (<></>)
    }
}