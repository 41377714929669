import React, {useCallback, useContext, useEffect, useState} from 'react'
import {useHttp} from '../hooks/http.hook'
import {AuthContext} from '../context/AuthContext'
import config from '../config.json'
import {removeCookieOfFolderId, setErrorWindow} from "../functions"
import {ModalWin} from "./ModalWin"
import {LoaderClass} from "./LoaderClass"

export const SaveNewNote = ({note}) => {

    const auth = useContext(AuthContext)
    const [allowed, setAllowed] = useState(true)
    const [dataModal, setDataModal] = useState({})
    const {request, loading} = useHttp()

    const getData = useCallback(async () => {
        let host
        window.location.host === 'localhost:3000' ? host = config.localHost : host = config.heroku
        try {
            const data = await request(
                `${host}/api/note/create`,
                'POST',
                {
                    userId: auth.userId,
                    parentFolder: note.parentFolder,
                    name: note.name,
                    body: note.body
                },
                {
                    Authorization: `Bearer ${auth.accessToken} RefreshToken ${auth.refreshToken} DeviceId ${auth.deviceId}`
                })
            if (data.status !== 201) {
                setAllowed(false)
                setDataModal(setErrorWindow('', data.code))
            } else {
                removeCookieOfFolderId(note.parentFolder)
                window.location.replace(`/folder/open/${note.parentFolder}`)
            }

        } catch (e) {
            window.location.replace("/main")
        }
    }, [auth, request, note])

    useEffect(() => {
        getData().then()
    }, [getData])

    if (loading) {
        return <LoaderClass />
    }

    if (!allowed) {
        return (
            <>
                <ModalWin data={dataModal}/>
            </>

        )
    }
}
