import React from 'react'
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import {Form, FormSelect} from "react-bootstrap"
import {Link} from "react-router-dom"

export class ModalCreateCardBankClass extends React.Component {

    render() {

        const {lang, show, data, addCardHandler, cancelHandler, closeHandler} = {
            lang: this.props.lang,
            show: this.props.show,
            data: this.props.data,
            addCardHandler: this.props.addCardHandler,
            cancelHandler: this.props.cancelHandler,
            closeHandler: this.props.closeHandler,
        }

        const changeNameHandler = (e) => {
            if (e.target.value !== '0') {
                e.target.classList.remove('is-invalid')
            } else {
                e.target.classList.add('is-invalid')
            }
        }

        const changeCurrencyHandler = (e) => {
            if (e.target.value !== '0') {
                e.target.classList.remove('is-invalid')
            } else {
                e.target.classList.add('is-invalid')
            }
        }

        const changeCardHandler = (e) => {
            const selectorDate = document.getElementById('selector-date')
            if (e.target.value !== 'Credit') {
                selectorDate.disabled = true
                document.querySelector('#div-selector-date').classList.add('d-none')
            } else {
                selectorDate.disabled = false
                document.querySelector('#div-selector-date').classList.remove('d-none')
            }
            if (e.target.value !== '0') {
                e.target.classList.remove('is-invalid')
            } else {
                e.target.classList.add('is-invalid')
            }
        }

        const changeDateHandler = (e) => {
            if (e.target.value !== '0') {
                e.target.classList.remove('is-invalid')
            } else {
                e.target.classList.add('is-invalid')
            }
        }

        setTimeout(() => {
            const selectType = document.querySelector('#select-type')
            const selectorDate = document.createElement('div')
            selectorDate.className = 'mt-4'
            selectorDate.id = 'div-selector-date'
            const labelDate = document.createElement('label')
            labelDate.innerHTML = lang.body.cardBankPage.labelDate
            labelDate.className = 'form-label'
            selectorDate.append(labelDate)
            const formSelect = document.createElement('select')
            formSelect.className = 'form-select is-invalid'
            formSelect.id = 'selector-date'
            formSelect.disabled = true
            formSelect.required = true
            formSelect.onchange = changeDateHandler

            for (let i = 0; i < 28; i++) {
                const option = document.createElement('option')
                const date = new Date().setDate(i + 1)
                option.value = String(date)
                option.innerHTML = String(i + 1)
                if (i === 0) {
                    option.selected = true
                    option.value = ''
                    option.innerHTML = lang.body.cardBankPage.labelDate
                }
                formSelect.append(option)
            }
            selectorDate.append(formSelect)
            selectType.after(selectorDate)
        }, 100)

        return (
            <>
                <Modal id={"create-modal"} show={show}>
                    <Modal.Header>
                        <Modal.Title className={'w-100 text-center'}>{data.title}</Modal.Title>
                        <Link to={'/money/settings'}>
                            <Button className={'ms-2'} variant={"close"} onClick={closeHandler}/>
                        </Link>
                    </Modal.Header>
                    <Modal.Body id={'modal-body'}>
                        <Form className={'was-validated'}>
                            <div className={'ms-3 me-3'}>
                                <p className={'text-start'}>{lang.body.cardBankPage.descAdd}</p>
                            </div>
                            <fieldset className={'teal p-3'}>
                                <div id={"divNameCard"} className={"mb-4"}>
                                    <label className={"form-label"}>
                                        {lang.body.cardBankPage.labelName}
                                    </label>
                                    <input
                                        type={"text"}
                                        name={"nameCard"}
                                        id={"nameCard"}
                                        className={"form-control is-invalid"}
                                        onChange={changeNameHandler}
                                        required={true}
                                        placeholder={lang.body.cardBankPage.placeholderName}
                                    />
                                </div>
                                <div id={"divType"} className={"mb-4"}>
                                    <label className={"form-label"}>
                                        {lang.body.cardBankPage.labelType}
                                    </label>
                                    <FormSelect id={'select-type'}
                                                required={true}
                                                className={'is-invalid'}
                                                onChange={changeCardHandler}>
                                        <option value={''}>{lang.body.cardBankPage.labelType}</option>
                                        <option value={"Credit"}>{lang.body.cardBankPage.textTypeCreditCard}</option>
                                        <option value={"Debit"}>{lang.body.cardBankPage.textTypeDebitCard}</option>
                                    </FormSelect>
                                </div>
                                <div id={"divCurrency"} className={"mb-4"}>
                                    <label className={"form-label"}>
                                        {lang.body.cardBankPage.selectCurrencyLabel}
                                    </label>
                                    <FormSelect id={'select-currency'}
                                                required={true}
                                                className={'is-invalid'}
                                                onChange={changeCurrencyHandler}>
                                        <option value={''}>{lang.body.cardBankPage.currency}</option>
                                        <option value={"USD"}>{lang.body.cardBankPage.usd}</option>
                                        <option value={"ILS"}>{lang.body.cardBankPage.ils}</option>
                                        <option value={"EUR"}>{lang.body.cardBankPage.eur}</option>
                                        <option value={"RUB"}>{lang.body.cardBankPage.rub}</option>
                                    </FormSelect>
                                </div>
                                <div id={"divDefault"} className={"mb-4"}>
                                    <label className={"form-label"}>
                                        {'Установить по умолчанию'}
                                    </label>
                                    <input id={'input-default'}
                                           type={'checkbox'}
                                           required={false}
                                           className={'form-check-input is-invalid ms-3'}>
                                    </input>
                                </div>
                            </fieldset>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant={data.btnOKColor}
                            onClick={() => {
                                const name = document.querySelector('#nameCard').value
                                const type = document.querySelector('#select-type').value
                                const currency = document.querySelector('#select-currency').value
                                const date = document.querySelector('#selector-date').value ? document.querySelector('#selector-date').value : 'current-day'
                                const isDefault = document.querySelector('#input-default').checked
                                const res = {
                                    name: name,
                                    type: type,
                                    currency: currency,
                                    date: date,
                                    isDefault: isDefault,
                                }
                                addCardHandler(res)
                            }}>
                            {data.btnOK}
                        </Button>
                        <Button
                            variant={data.btnCancelColor}
                            onClick={cancelHandler}>
                            {data.btnCancel}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}