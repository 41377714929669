import React, {useCallback, useContext, useEffect} from 'react'
import {useHttp} from '../hooks/http.hook'
import {AuthContext} from '../context/AuthContext'
import config from '../config.json'
import {LoaderClass} from "./LoaderClass"
import {MainPage} from "../pages/MainPage"

export const ExitPage = () => {
    const {request, loading} = useHttp()
    const auth = useContext(AuthContext)

    const fetch = useCallback(async () => {
        let host
        window.location.host === 'localhost:3000' ? host = config.localHost : host = config.heroku
        try {
            await request(`${host}/api/user/logout`,
                'POST',
                null,
                {
                    Authorization: `Bearer ${auth.accessToken} RefreshToken ${auth.refreshToken} DeviceId ${auth.deviceId}`
                }
            )
            auth.logout()
        } catch (e) {
            window.location.replace("/")
        }
    }, [request, auth])

    useEffect(() => {
        fetch().then()
    }, [fetch, auth])

    if (loading) {
        return <LoaderClass />
    }

    return (
        <>
            <MainPage />
        </>
    )
}