import React, {useCallback, useContext, useEffect} from 'react'
import {useHttp} from '../hooks/http.hook'
import {AuthContext} from '../context/AuthContext'
import config from '../config.json'
import {LoaderClass} from "./LoaderClass"
import {getQueryParams, refreshAT} from "../functions"

export const UploadAvatarToPage = () => {
    const {request, loading} = useHttp()
    const auth = useContext(AuthContext)

    const fetch = useCallback(async () => {
        let host
        window.location.host === 'localhost:3000' ? host = config.localHost : host = config.heroku
        try {
            await request(
                `${host}/api/user/avatar/update`,
                'POST',
                {avatar: `${getQueryParams().avatar}`},
                {
                    Authorization: `Bearer ${auth.accessToken} RefreshToken ${auth.refreshToken} DeviceId ${auth.deviceId}`
                }
            )
            await refreshAT(auth, request)
        } catch (e) {
            window.location.replace("/profile")
        }
    }, [request, auth])

    useEffect(() => {
        fetch().then(() => {
           window.location.replace('/upload-avatar')
        })
    }, [fetch, auth, request])

    if (loading) {
        return <LoaderClass/>
    }
}