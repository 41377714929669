import React from 'react'
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import {Link} from "react-router-dom"

export class ModalCreateYearClass extends React.Component {

    render() {

        const {show, data, currentYear, createNewYearHandler, cancelHandler, closeHandler} = {
            show: this.props.show,
            data: this.props.data,
            currentYear: this.props.currentYear,
            createNewYearHandler: this.props.createNewYearHandler,
            cancelHandler: this.props.cancelHandler,
            closeHandler: this.props.closeHandler,
        }

        return (
            <>
                <Modal id={"create-modal"} show={show}>
                    <Modal.Header>
                        <Modal.Title className={'w-100'}>{data.title}</Modal.Title>
                        <Link to={'/money/main'}>
                            <Button className={'ms-2'} variant={"close"} onClick={closeHandler}/>
                        </Link>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{`${data.desc}. Какой год Вы хотите создать?`}</p>
                        <input id={"create-year"} type={"text"} className={"form-control w-25"} defaultValue={currentYear}/>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant={data.btnOKColor}
                            onClick={createNewYearHandler}>
                            {data.btnOK}
                        </Button>
                        <Button
                            variant={data.btnCancelColor}
                            onClick={cancelHandler}>
                            {data.btnCancel}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}