import React, {useContext, useEffect, useState} from 'react'
import {useHttp} from '../hooks/http.hook'
import {AuthContext} from '../context/AuthContext'
import config from '../config.json'
import {objectLang, removeCookieOfFolderId, setErrorWindow} from "../functions"
import {ModalWin} from "../elements/ModalWin"
import {LoaderClass} from "../elements/LoaderClass"
import {PathLineClass} from "../elements/PathLineClass"

export const CreateFolderPage = () => {
    localStorage.setItem('page', 'CreateFolderPage')
    const $ = require("jquery")

    const {request, loading} = useHttp()
    const auth = useContext(AuthContext)
    const urlParams = new URLSearchParams(window.location.search)
    const folderId = urlParams.get('folderId')
    const lang = objectLang().language
    const [allowed, setAllowed] = useState(true)
    const [dataModal, setDataModal] = useState({})

    useEffect(() => {
        window.M.updateTextFields()
    }, [])

    const clickHandler = async () => {
        let host
        window.location.host === 'localhost:3000' ? host = config.localHost : host = config.heroku
        if (!$('#name').val()) {
            setAllowed(false)
            setDataModal(setErrorWindow(
                '',
                591,
                '',
                '',
                `replaceTo /create-folder?folderId=${folderId}`
            ))
            return
        }
        try {
            const data = await request(
                `${host}/api/folder/create`,
                'POST',
                {
                    parentFID: folderId,
                    userId: auth.userId,
                    name: document.querySelector('#name').value
                },
                {
                    Authorization: `Bearer ${auth.accessToken} RefreshToken ${auth.refreshToken} DeviceId ${auth.deviceId}`
                })

            if (data.status > 200) {
                setAllowed(false)
                setDataModal(setErrorWindow('', data.code))
            } else {
                removeCookieOfFolderId(folderId)
                window.location.replace(`/folder/open/${data.folder.parentFID}`)
            }

        } catch (e) {
            window.location.replace("/main")
        }
    }

    if (loading) {
        return <LoaderClass />
    }

    if (!allowed) {
        return (
            <>
                <ModalWin data={dataModal}/>
            </>

        )
    }

    return (
        <>
            <PathLineClass documents={JSON.parse(localStorage.getItem('documents'))}/>
            <div className={"container d-flex flex-wrap justify-content-center"}>
                <div className={"col-12 col-md-9 mb-3 mt-3"}>
                    <input
                        placeholder={lang.body.createFolderPage.placeholderName}
                        type={"text"}
                        className={"form-control"}
                        id={"name"}
                    />
                </div>
                <button
                    className={"col-4 col-md-3 btn btn-info border-0 me-2"}
                    type={"button"}
                    onClick={() => {
                        window.location.replace(localStorage.getItem('pathBack'))
                    }}>
                    {`${lang.body.general.btnBack}`}
                </button>
                <button
                    className={"col-6 col-md-4 btn btn-success border-0"}
                    type={"button"}
                    onClick={clickHandler}>
                    {lang.body.createFolderPage.btnCreateFolder}
                </button>
            </div>
        </>

    )
}
