import React, {useCallback, useContext, useEffect, useState} from 'react'
import {AuthContext} from '../context/AuthContext'
import {getDateNumber, objectLang, setErrorWindow} from "../functions"
import {useHttp} from "../hooks/http.hook"
import config from "../config.json"
import {ModalWin} from "../elements/ModalWin"
import {LoaderClass} from "../elements/LoaderClass"
import {MonthDetailClass} from "../components/MonthDetailClass"
import {createBrowserHistory} from "history"

export const MonthDetailPage = () => {
    localStorage.setItem('page', 'MonthDetailPage')

    const auth = useContext(AuthContext)
    const {loading, request} = useHttp()
    const [allowed, setAllowed] = useState(true)
    const [dataModal, setDataModal] = useState({})
    const [dataMonth, setDataMonth] = useState({})
    const [expandIncome, setExpandIncome] = useState(false)
    const [expandExpense, setExpandExpense] = useState(false)
    const urlParams = new URLSearchParams(window.location.search)
    const month = urlParams.get('month')
    const year = urlParams.get('year')
    const lang = objectLang().language

    const fetch = useCallback(async () => {
        let host
        window.location.host === 'localhost:3000' ? host = config.localHost : host = config.heroku
        try {
            const data = await request(
                `${host}/api/money/month/detail?year=${year}&month=${month}`,
                'GET',
                null,
                {
                    Authorization: `Bearer ${auth.accessToken} RefreshToken ${auth.refreshToken} DeviceId ${auth.deviceId}`
                })

            if (data.code === 6041) {
                window.location.reload()
            }

            if (data.status === 200) {
                setDataMonth(data['dataMonth'])
            }

            setAllowed(true)
            setDataModal(setErrorWindow('', data.code))

        } catch (e) {
            window.location.replace("/money/main")
        }
    }, [auth, request, year, month])

    useEffect(() => {
        fetch().then()
    }, [fetch])

    const updating = async (dataObject) => {
        let host
        window.location.host === 'localhost:3000' ? host = config.localHost : host = config.heroku
        try {
            const data = await request(
                `${host}/api/money/month/update`,
                'PUT',
                dataObject,
                {
                    Authorization: `Bearer ${auth.accessToken} RefreshToken ${auth.refreshToken} DeviceId ${auth.deviceId}`
                })

            if (data.code === 6041) {
                window.location.reload()
            }

            if (data.status === 200) {
                window.location.reload()
            }

        } catch (e) {
            window.location.replace("/money/main")
        }
    }

    const updateHandler = (data) => {

        const viewCalendar = () => {
            const calendar = document.querySelector('.calendar')
            if (calendar.style.display === 'block') {
                calendar.style.display = 'none'
            } else {
                calendar.style.display = 'block'
            }
        }

        const means = []
        for (const i in data['means']) {
            const item = data['means'][i][1] === 'Cash' ? lang.body.moneyPage.cash : data['means'][i][1]
            means.push([data['means'][i][0], item])
        }

        // console.log(data.purpose)

        const row = document.querySelector(`#${data['row']}`)
        const divDate = document.querySelector(`#${data['divDate']}`)
        const div = document.querySelector(`#${data['div']}`)
        const htmlDate = document.querySelector(`#${data['date']}`)
        const htmlCard = document.querySelector(`#${data['cardId']}`)
        const htmlName = document.querySelector(`#${data['name']}`)
        const htmlValue = document.querySelector(`#${data['value']}`)
        const date = htmlDate.innerHTML
        const name = htmlName.innerHTML
        const value = htmlValue.innerHTML.split(' ')[0]
        const divIconUpdate = document.querySelector(`#${data['divIconUpdate']}`)
        const divIconRemove = document.querySelector(`#${data['divIconRemove']}`)
        const htmlIconUpdate = document.querySelector(`#${data['iIconUpdate']}`)
        const htmlIconRemove = document.querySelector(`#${data['iIconRemove']}`)
        const inputDate = document.createElement('input')
        const selectCat = document.createElement('select')
        const inputName = document.createElement('input')
        const inputValue = document.createElement('input')
        const sending = document.createElement('i')
        const deleting = document.createElement('i')
        inputDate.id = 'input-date'
        inputDate.className = 'form-control'
        inputDate.style.width = '41.5%'
        inputDate.style.padding = '0.2rem 0.375rem'
        inputDate.defaultValue = date
        selectCat.id = 'select-card'
        selectCat.className = 'form-select'
        selectCat.style.width = '41%'
        selectCat.style.padding = '0.2rem 0.375rem'
        const oldSel = data['oldName'].split(';')[2]
        for (const i in means) {
            const option = document.createElement('option')
            option.value = means[i][0]
            option.innerHTML = means[i][1]
            if (option.value === oldSel) {
                option.selected = true
            }
            selectCat.append(option)
        }
        inputName.className = 'form-control'
        inputName.style.width = '70%'
        inputName.style.padding = '0.2rem 0.375rem'
        inputName.defaultValue = name
        inputValue.className = 'form-control'
        inputValue.style.width = '30%'
        inputValue.style.padding = '0.2rem 0.375rem'
        inputValue.defaultValue = value
        sending.className = 'fa-regular fa-paper-plane d-flex align-items-center text-primary'
        deleting.className = 'fa-solid fa-trash-can d-flex align-items-center text-danger'
        divIconUpdate.append(sending)
        divIconRemove.append(deleting)

        htmlDate.remove()
        htmlCard.remove()
        htmlName.remove()
        htmlValue.remove()
        htmlIconUpdate.remove()
        htmlIconRemove.remove()
        divDate.classList.remove('justify-content-between')
        divDate.classList.add('justify-content-start')
        divDate.append(inputDate)
        divDate.append(selectCat)
        div.append(inputName)
        div.append(inputValue)
        row.append(divDate)
        row.append(div)
        row.append(divIconUpdate)
        row.append(divIconRemove)

        if (data.purpose === 'income') {
            selectCat.classList.add('d-none')
        } else {
            selectCat.classList.remove('d-none')
        }

        inputDate.onclick = viewCalendar

        sending.onclick = () => {
            localStorage.setItem('pathMonthDetail', createBrowserHistory().location.pathname + createBrowserHistory().location.search)
            const dateNum = getDateNumber(inputDate.value)
            const fullName = `${dateNum};${inputName.value};${selectCat.value}`
            updating({
                year: data['nameYear'],
                month: data['nameMonth'],
                purpose: data['purpose'],
                oldName: data['oldName'],
                oldValue: data['oldValue'],
                newName: fullName,
                newValue: Number(inputValue.value),
            }).then()
        }

        deleting.onclick = () => {
            deleteHandler({
                nameYear: data['nameYear'],
                nameMonth: data['nameMonth'],
                purpose: data['purpose'],
                nameField: data['oldName']
            })
        }
    }

    const expandCollapseHandler = (id, purpose) => {
        const i = document.getElementById(id)
        if (i.className === 'fa-solid fa-chevron-down fs-3 opacity-75 p-0') {
            i.className = 'fa-solid fa-chevron-up fs-3 opacity-75 p-0'
            if (purpose === 'income') {
                setExpandIncome(true)
            } else {
                setExpandExpense(true)
            }
        } else {
            i.className = 'fa-solid fa-chevron-down fs-3 opacity-75 p-0'
            if (purpose === 'income') {
                setExpandIncome(false)
            } else {
                setExpandExpense(false)
            }
        }
    }

    const deleteHandler = (dataObject) => {
        const field = encodeURI(dataObject['nameField'])
        localStorage.setItem('pathBackRemoveLine', createBrowserHistory().location.pathname + createBrowserHistory().location.search)
        setAllowed(false)
        setDataModal(setErrorWindow(lang.modal.warnings.title,
            '',
            lang.modal.warnings.desc.deleteOneMoneyLine,
            '',
            `replaceTo /money/month/remove-line?year=${dataObject['nameYear']}&month=${dataObject['nameMonth']}&purpose=${dataObject['purpose']}&field=${field}`
        ))
    }

    localStorage.setItem('moneyPathBack', `/money/month/detail?year=${year}&month=${month}`)

    if (loading) {
        return <LoaderClass/>
    }

    if (!allowed) {
        return (
            <ModalWin data={dataModal}/>
        )
    }

    return (
        <>
            <MonthDetailClass
                data={dataMonth}
                updateHandler={updateHandler}
                expandCollapseHandler={expandCollapseHandler}
                expandIncome={expandIncome}
                expandExpense={expandExpense}
                deleteHandler={deleteHandler}
            />
        </>
    )
}
