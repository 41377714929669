import React, {useCallback, useContext, useEffect, useState} from 'react'
import {AuthContext} from '../context/AuthContext'
import {useHttp} from "../hooks/http.hook"
import config from '../config.json'
import {setErrorWindow} from "../functions"
import {LoaderClass} from "./LoaderClass"
import {ModalWin} from "./ModalWin"

export const RemoveLine = () => {

    const auth = useContext(AuthContext)
    const [allowed, setAllowed] = useState(true)
    const [dataModal, setDataModal] = useState({})
    const {request, loading} = useHttp()

    const data = useCallback(async () => {
        const urlParams = new URLSearchParams(window.location.search)
        const year = urlParams.get('year')
        const month = urlParams.get('month')
        const purpose = urlParams.get('purpose')
        const field = decodeURI(urlParams.get('field'))
        let host
        window.location.host === 'localhost:3000' ? host = config.localHost : host = config.heroku
        try {
            const data = await request(
                `${host}/api/money/month/deleting`,
                'DELETE',
                {year, month, purpose, field},
                {
                    Authorization: `Bearer ${auth.accessToken} RefreshToken ${auth.refreshToken} DeviceId ${auth.deviceId}`
                })

            if (data.code === 6041) {
                window.location.reload()
            }

            if (data.status === 200) {
                window.location.replace(localStorage.getItem('pathBackRemoveLine'))
            } else {
                setAllowed(false)
                setDataModal(setErrorWindow('', data.code))
            }

        } catch (e) {
            window.location.replace("/main")
        }
    }, [request, auth])

    useEffect(() => {
        data().then()
    }, [data])

    if (loading) {
        return <LoaderClass />
    }

    if (!allowed) {
        return (
            <>
                <ModalWin data={dataModal}/>
            </>

        )
    }
}
