import React, {useContext, useState} from 'react'
import {useHttp} from '../hooks/http.hook'
import {AuthContext} from '../context/AuthContext'
import {objectLang, setErrorWindow} from "../functions"
import {ModalWin} from "../elements/ModalWin"
import {LoaderClass} from "../elements/LoaderClass"
import config from "../config.json"
import {ModalCreateCardBankClass} from "../elements/ModalCreateCardBankClass"
import {MoneySettingsPage} from "./MoneySettingsPage"

export const AddCardBankPage = () => {
    localStorage.setItem('page', 'AddCardBankPage')
    const lang = objectLang().language
    const auth = useContext(AuthContext)
    const {loading, request} = useHttp()
    const [allowed, setAllowed] = useState(true)
    const [dataModal, setDataModal] = useState({})
    const [show, setShow] = useState(true)

    const data = {
        title: lang.body.cardBankPage.titleAdd,
        desc: lang.body.cardBankPage.descAdd,
        btnOKColor: 'success',
        btnCancelColor: 'secondary',
        btnOK: lang.body.cardBankPage.btnCreate,
        btnCancel: lang.body.cardBankPage.btnCancel
    }

    const closeHandler = () => {
        setShow(false)
    }

    const addCardHandler = async (bodyObject) => {
        if (!bodyObject['name'] || !bodyObject['type'] || !bodyObject['currency'] || !bodyObject['date']) {
            setAllowed(false)
            setDataModal(setErrorWindow(
                '',
                591,
                '',
                '',
                `replaceTo /money/add-card`
            ))
            return
        }
        let host
        window.location.host === 'localhost:3000' ? host = config.localHost : host = config.heroku
        try {
            const data = await request(
                `${host}/api/money/settings/add-card`,
                'POST',
                bodyObject,
                {
                    Authorization: `Bearer ${auth.accessToken} RefreshToken ${auth.refreshToken} DeviceId ${auth.deviceId}`
                })
            if (data.status > 201) {
                setAllowed(false)
                setDataModal(setErrorWindow('', data.code))
            } else {
                window.location.replace("/money/settings")
            }

        } catch (e) {
            window.location.replace("/money/main")
        }
    }

    const cancelHandler = async () => {
        window.location.replace('/money/settings')
    }

    if (loading) {
        return <LoaderClass/>
    }

    if (!allowed) {
        return (
            <ModalWin data={dataModal}/>
        )
    }

    return (
        <>
            <MoneySettingsPage/>
            <ModalCreateCardBankClass
                lang={lang}
                show={show}
                data={data}
                addCardHandler={addCardHandler}
                cancelHandler={cancelHandler}
                closeHandler={closeHandler}
            />
        </>
    )
}