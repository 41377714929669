import React, {useCallback, useContext, useEffect, useState} from 'react'
import {useHttp} from '../hooks/http.hook'
import {AuthContext} from '../context/AuthContext'
import {objectLang, setErrorWindow} from "../functions"
import config from '../config.json'
import {ModalWin} from "../elements/ModalWin"
import {LoaderClass} from "../elements/LoaderClass"
import {MainMoneyClass} from "../components/MainMoneyClass"
import {createBrowserHistory} from "history"

export const MainMoneyPage = () => {
    localStorage.setItem('page', 'MainMoneyPage')
    localStorage.setItem('moneyPathBack', '/money/main')
    const auth = useContext(AuthContext)
    const {loading, request} = useHttp()
    const [allowed, setAllowed] = useState(true)
    const [delay, setDelay] = useState(false)
    const [dataModal, setDataModal] = useState({})
    const [money, setMoney] = useState(null)

    const lang = objectLang().language

    const getData = useCallback(async () => {
        let host
        window.location.host === 'localhost:3000' ? host = config.localHost : host = config.heroku
        try {
            const data = await request(
                `${host}/api/money/get`,
                'GET',
                null,
                {
                    Authorization: `Bearer ${auth.accessToken} RefreshToken ${auth.refreshToken} DeviceId ${auth.deviceId}`
                })

            if (data.status !== 200) {
                if (data.status === 404) {
                    setAllowed(false)
                    setDataModal(setErrorWindow(
                        '',
                        data.code, data.message,
                        '',
                        'replaceTo /money/year/create',
                        '',
                        '',
                        'success',
                        ''
                    ))
                } else {
                    setAllowed(false)
                    setDataModal(setErrorWindow('', data.code, data.message, '', 'replaceTo /main'))
                }
            } else {
                setMoney(data['money'])
                localStorage.setItem('pathBack', createBrowserHistory().location.pathname + createBrowserHistory().location.search)
            }

        } catch (e) {
            // window.location.replace("/main")
        }
    }, [request, auth])

    useEffect(() => {
        getData().then()
    }, [getData])

    if (!allowed) {
        return (
            <ModalWin data={dataModal}/>
        )
    }

    setTimeout(() => {
        setDelay(true)
    }, 100)

    if (loading || !delay) {
        return <LoaderClass/>
    }

    if (delay) {
        return (
            <>
                <MainMoneyClass
                    lang={lang}
                    money={money}
                />
            </>
        )
    }
}