import React from 'react'
import {hideHandler, filterMoneyHandler, objectLang, parseNameWithDate, sortDate} from "../functions"
import {Link} from "react-router-dom"
import {CalendarClass} from "./CalendarClass"
import {MoneyNav} from "../elements/MoneyNav"

export class MonthDetailClass extends React.Component {

    render() {

        const {
            data,
            updateHandler,
            expandCollapseHandler,
            expandIncome,
            expandExpense,
            deleteHandler
        } = {
            data: this.props.data,
            updateHandler: this.props.updateHandler,
            expandCollapseHandler: this.props.expandCollapseHandler,
            expandIncome: this.props.expandIncome,
            expandExpense: this.props.expandExpense,
            deleteHandler: this.props.deleteHandler,
        }

        const lang = objectLang().language

        let currency = data['currency'],
            nameMonth = `${lang.body.moneyPage[data['nameMonth']]}`

        const arrIncomesCategories = [],
            arrExpensesCategories = [],
            arrIncomesCategoriesAll = [],
            arrExpensesCategoriesAll = [],
            incomes = data['incomesOfNamesTotals'],
            expenses = data['expensesOfNamesTotals'],
            incomesAll = data['incomesAllFields'],
            expensesAll = data['expensesAllFields']

        for (const name in incomes) {
            const value = incomes[name]
            arrIncomesCategories.push([name, value])
        }

        for (const name in expenses) {
            const value = expenses[name]
            arrExpensesCategories.push([name, value])
        }

        for (const name in incomesAll) {
            const value = incomesAll[name]
            arrIncomesCategoriesAll.push([parseNameWithDate(name).date, parseNameWithDate(name).name, parseNameWithDate(name).means, value, name])
        }

        for (const name in expensesAll) {
            const value = expensesAll[name]
            arrExpensesCategoriesAll.push([parseNameWithDate(name).date, parseNameWithDate(name).name, parseNameWithDate(name).means, value, name])
        }

        arrExpensesCategoriesAll.sort((a, b) => {
            return sortDate(a, b, 0)
        })
        arrIncomesCategoriesAll.sort((a, b) => {
            return sortDate(a, b, 0)
        })

        arrExpensesCategories.reverse()
        arrIncomesCategories.reverse()

        const dataNav = {
            pathBack: `/money?year=${data['nameYear']}`,
            isMain: false,
            isMoney: true,
            isSetting: false,
            isAddIncome: true,
            isAddCredit: false,
            isAddPerPayment: false,
            isAddPurchase: true
        }

        return (
            <>
                <MoneyNav data={dataNav}/>
                <div className={"mt-3 col col-sm-11 col-lg-12 col-xl-9 m-auto"}>
                    <div className={"card"}>
                        <div className={"card-body"}>
                            <div className={'col col-12 d-flex justify-content-between mb-3'}>
                                <div className={'col col-6 d-flex justify-content-end'}>
                                    <h5 className={"card-title m-auto fs-5"}>{`${nameMonth} ${data['nameYear']}`}</h5>
                                </div>
                                <div className={'col col-5 d-flex justify-content-end'}>
                                    <h5 className={"card-title m-auto pe-2"}>{lang.body.moneyPage.main.balance}&nbsp;{data['balanceMonth']}</h5>
                                </div>
                                <Link to={localStorage.getItem('pathBack')}
                                      id={"button-close-month"}
                                      className={"col col-1 btn-close fs-3 m-0"}
                                      aria-label={"Close"}>
                                </Link>
                            </div>

                            <CalendarClass/>

                            <div className={`year-income-expense m-1`}>

                                {/* Месячные Доходы */}
                                <div className={'col border border-3 rounded-2 m-2 ps-2 pe-2 pt-3 pb-3'}>
                                    <div id={'div-incomes-month'} className={'col align-self-end'}>
                                        <div className={'filter row col w-50 d-flex flex-nowrap mb-2'}>
                                            <button className={'btn col col-1 ms-3'}
                                                    onClick={() => {
                                                        expandCollapseHandler('btn-income-expand-collapse', 'income')
                                                    }}>
                                                <i id={'btn-income-expand-collapse'}
                                                   className={"fa-solid fa-chevron-down fs-3 opacity-75 p-0"}/>
                                            </button>
                                            <h4 id={'add-income-month-name col col-10'}
                                                className={"d-flex align-items-end justify-content-center"}>{lang.body.moneyPage.main.incomes}</h4>
                                            {!expandIncome && <input
                                                id={'incomes-filter'}
                                                className={'form-control col col-1 w-75'}
                                                placeholder={'Filter'}
                                                onChange={(event) => {
                                                    const resultArrNames = filterMoneyHandler(event, arrIncomesCategoriesAll, 'month-all')
                                                    hideHandler(resultArrNames, 'div-incomes-month', 'month-all')
                                                }}/>}
                                            {expandIncome && <input
                                                id={'incomes-filter'}
                                                className={'form-control col col-1 w-75'}
                                                placeholder={'Filter'}
                                                onChange={(event) => {
                                                    const resultArrNames = filterMoneyHandler(event, arrIncomesCategories, 'month')
                                                    hideHandler(resultArrNames, 'div-incomes-month', 'month')
                                                }}/>}
                                        </div>

                                        {!expandIncome && arrIncomesCategories.map((category, index) => {
                                            const nameCat = category[0]
                                            const value = category[1]
                                            if (nameCat !== 'total') {
                                                return (
                                                    <div id={`income-row-${index}`} className={'row m-1 mt-3'}
                                                         key={index}>
                                                        <div id={`income-div-${index}`}
                                                             className={'col col-12 d-flex justify-content-between border-bottom'}>
                                                            <h6 id={`income-name-${index}`}
                                                                className={'m-0'}>{nameCat}</h6>
                                                            <p id={`income-value-${index}`}
                                                               className={'m-0'}>{`${value} (${currency})`}</p>
                                                        </div>
                                                    </div>
                                                )
                                            } else {
                                                return (<></>)
                                            }
                                        })}
                                        {expandIncome && arrIncomesCategoriesAll.map((category, index) => {
                                            const dateCat = category[0]
                                            const nameCat = category[1]
                                            const value = category[3]
                                            const fullName = category[4]
                                            if (nameCat !== 'total') {
                                                return (
                                                    <div id={`income-row-${index}`} className={'row m-1 mt-3'}
                                                         key={index}>
                                                        <div id={`income-div-date-${index}`}
                                                             className={'d-flex justify-content-between'}>
                                                            <p id={`income-date-${index}`}
                                                               className={'m-0'}>{dateCat}</p>
                                                            <p id={`income-cardId-${index}`}
                                                               className={'m-0'}>{''}</p>
                                                        </div>
                                                        <div id={`income-div-${index}`}
                                                             className={'col col-10 d-flex justify-content-between border-bottom'}>
                                                            <h6 id={`income-name-${index}`}
                                                                className={'m-0'}>{nameCat}</h6>
                                                            <p id={`income-value-${index}`}
                                                               className={'m-0'}>{`${value} (${currency})`}</p>
                                                        </div>
                                                        <div id={`income-div-icon-update-${index}`}
                                                             className={'col col-1 d-flex justify-content-end border-bottom text-primary'}>
                                                            <i id={`income-i-icon-update-${index}`}
                                                               className={"fa-solid fa-pen-to-square d-flex align-items-center"}
                                                               onClick={(e) => {
                                                                   updateHandler({
                                                                       event: e,
                                                                       means: data['cards'],
                                                                       nameYear: data['nameYear'],
                                                                       nameMonth: data['nameMonth'],
                                                                       purpose: 'income',
                                                                       oldName: fullName,
                                                                       oldValue: value,
                                                                       row: `income-row-${index}`,
                                                                       div: `income-div-${index}`,
                                                                       divDate: `income-div-date-${index}`,
                                                                       date: `income-date-${index}`,
                                                                       cardId: `income-cardId-${index}`,
                                                                       name: `income-name-${index}`,
                                                                       value: `income-value-${index}`,
                                                                       divIconUpdate: `income-div-icon-update-${index}`,
                                                                       iIconUpdate: `income-i-icon-update-${index}`,
                                                                       divIconRemove: `income-div-icon-remove-${index}`,
                                                                       iIconRemove: `income-i-icon-remove-${index}`
                                                                   })
                                                               }}
                                                            />
                                                        </div>
                                                        <div id={`income-div-icon-remove-${index}`}
                                                             className={'col col-1 d-flex justify-content-end border-bottom'}>
                                                            <i id={`income-i-icon-remove-${index}`}
                                                               className={"fa-solid fa-trash-can d-flex align-items-center text-danger"}
                                                               onClick={() => {
                                                                   deleteHandler({
                                                                       nameYear: data['nameYear'],
                                                                       nameMonth: data['nameMonth'],
                                                                       purpose: 'income',
                                                                       nameField: fullName
                                                                   })
                                                               }}
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            } else {
                                                return (<></>)
                                            }
                                        })}

                                    </div>

                                    <div className={'filter row m-1 mt-4'}>
                                        <div className={'col col-12 d-flex justify-content-between border-bottom'}>
                                            <h6 className={'m-0 text-success'}>{'Всего доходов'}</h6>
                                            <p className={'m-0'}>{`${data['incomesMonthTotal']} (${currency})`}</p>
                                        </div>
                                    </div>

                                </div>

                                {/* Месячные Расходы */}
                                <div className={'col border border-3 rounded-2 m-2 ps-2 pe-2 pt-3 pb-3'}>
                                    <div id={'div-expenses-month'} className={'add-expense ps-2 pe-2'}>
                                        <div className={'filter row col w-50 d-flex flex-nowrap mb-2'}>
                                            <button className={'btn col col-1 ms-3'}
                                                    onClick={() => {
                                                        expandCollapseHandler('btn-expense-expand-collapse', 'expense')
                                                    }}>
                                                <i id={'btn-expense-expand-collapse'}
                                                   className={"fa-solid fa-chevron-down fs-3 opacity-75 p-0"}/>
                                            </button>
                                            <h4 id={'add-expense-month-name col col-10'}
                                                className={"d-flex align-items-end justify-content-center"}>{lang.body.moneyPage.main.expenses}</h4>
                                            {!expandExpense && <input
                                                id={'expenses-filter'}
                                                className={'form-control col col-1 w-75'}
                                                placeholder={'Filter'}
                                                onChange={(event) => {
                                                    const resultArrNames = filterMoneyHandler(event, arrExpensesCategoriesAll, 'month-all')
                                                    hideHandler(resultArrNames, 'div-expenses-month', 'month-all')
                                                }}/>}
                                            {expandExpense && <input
                                                id={'expenses-filter'}
                                                className={'form-control col col-1 w-75'}
                                                placeholder={'Filter'}
                                                onChange={(event) => {
                                                    const resultArrNames = filterMoneyHandler(event, arrExpensesCategories, 'month')
                                                    hideHandler(resultArrNames, 'div-expenses-month', 'month')
                                                }}/>}
                                        </div>

                                        {!expandExpense && arrExpensesCategories.map((category, index) => {
                                            // const dateCat = category[0]
                                            const nameCat = category[0]
                                            const value = category[1]
                                            if (nameCat !== 'total') {
                                                return (
                                                    <div id={`expense-row-${index}`} className={'row m-1 mt-3'}
                                                         key={index}>
                                                        <div id={`expense-div-${index}`}
                                                             className={'col col-12 d-flex justify-content-between border-bottom'}>
                                                            <h6 id={`expense-name-${index}`}
                                                                className={'m-0'}>{nameCat}</h6>
                                                            <p id={`expense-value-${index}`}
                                                               className={'m-0'}>{`${value} (${currency})`}</p>
                                                        </div>
                                                    </div>
                                                )
                                            } else {
                                                return (<></>)
                                            }
                                        })}
                                        {expandExpense && arrExpensesCategoriesAll.map((category, index) => {
                                            const dateCat = category[0]
                                            const nameCat = category[1]
                                            const value = category[3]
                                            const fullName = category[4]
                                            if (nameCat !== 'total') {
                                                return (

                                                    <div id={`expense-row-${index}`} className={'row m-1 mt-3'}
                                                         key={index}>
                                                        <div id={`expense-div-date-${index}`}
                                                             className={'d-flex justify-content-between'}
                                                             style={{width: "100%"}}>
                                                            <p id={`expense-date-${index}`}
                                                               className={'m-0'}>{dateCat}</p>
                                                            <p id={`expense-cardId-${index}`}
                                                               className={'m-0'}>{''}</p>
                                                        </div>
                                                        <div id={`expense-div-${index}`}
                                                             className={'col col-10 d-flex justify-content-between border-bottom'}>
                                                            <h6 id={`expense-name-${index}`}
                                                                className={'m-0'}>{nameCat}</h6>
                                                            <p id={`expense-value-${index}`}
                                                               className={'m-0'}>{`${value} (${currency})`}</p>
                                                        </div>
                                                        <div id={`expense-div-icon-update-${index}`}
                                                             className={'col col-1 d-flex justify-content-end border-bottom'}>
                                                            <i id={`expense-i-icon-update-${index}`}
                                                               className={"fa-solid fa-pen-to-square d-flex align-items-center text-primary"}
                                                               onClick={(e) => {
                                                                   updateHandler({
                                                                       event: e,
                                                                       means: data['cards'],
                                                                       nameYear: data['nameYear'],
                                                                       nameMonth: data['nameMonth'],
                                                                       purpose: 'expense',
                                                                       oldName: fullName,
                                                                       oldValue: value,
                                                                       row: `expense-row-${index}`,
                                                                       div: `expense-div-${index}`,
                                                                       divDate: `expense-div-date-${index}`,
                                                                       date: `expense-date-${index}`,
                                                                       cardId: `expense-cardId-${index}`,
                                                                       name: `expense-name-${index}`,
                                                                       value: `expense-value-${index}`,
                                                                       divIconUpdate: `expense-div-icon-update-${index}`,
                                                                       iIconUpdate: `expense-i-icon-update-${index}`,
                                                                       divIconRemove: `expense-div-icon-remove-${index}`,
                                                                       iIconRemove: `expense-i-icon-remove-${index}`,
                                                                   })
                                                               }}
                                                            />
                                                        </div>
                                                        <div id={`expense-div-icon-remove-${index}`}
                                                             className={'col col-1 d-flex justify-content-end border-bottom'}>
                                                            <i id={`expense-i-icon-remove-${index}`}
                                                               className={"fa-solid fa-trash-can d-flex align-items-center text-danger"}
                                                               onClick={() => {
                                                                   deleteHandler({
                                                                       nameYear: data['nameYear'],
                                                                       nameMonth: data['nameMonth'],
                                                                       purpose: 'expense',
                                                                       nameField: fullName
                                                                   })
                                                               }}
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            } else {
                                                return (<></>)
                                            }
                                        })}
                                        <div className={'filter row m-1 mt-4'}>
                                            <div className={'col col-12 d-flex justify-content-between border-bottom'}>
                                                <h6 className={'m-0 text-danger'}>{'Всего расходов'}</h6>
                                                <p className={'m-0'}>{`${data['expensesMonthTotal']} (${currency})`}</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
