import React from 'react'
import {Link} from "react-router-dom"
import {MoneyNav} from "../elements/MoneyNav"

export class YearMoneyClass extends React.Component {

    render() {

        const {lang, dataYear, preYear, nextYear} = {
            lang: this.props.lang,
            dataYear: this.props.dataYear,
            preYear: this.props.preYear,
            nextYear: this.props.nextYear,
        }

        let yearName = 'year', currency = '$', balance = '0'
        const months = [], dataMonths = []

        for (const field in dataYear) {
            yearName = field // название года
            currency = dataYear[field].currency // валюта
            balance = dataYear[field].balance // баланс
            const month = dataYear[field].months
            for (const m in month) {
                months.push(m) // Массив имён месяцев
                dataMonths.push(month[m]) // Массив объектов для каждого месяца года
            }
        }

        const dataNav = {
            pathBack: '/money/main',
            isMain: false,
            isMoney: true,
            isSetting: false,
            isAddIncome: false,
            isAddCredit: false,
            isAddPerPayment: true,
            isAddPurchase: true
        }

        return (
            <>
                <MoneyNav data={dataNav}/>
                <div className={"mt-3 col col-sm-11 col-lg-12 col-xl-9 d-flex justify-content-center m-auto"}>
                    <div className={"row"}>
                        <div className={"col-sm-12"}>
                            <div className={"card mb-2"}>
                                <Link to={`/money/year/detail?year=${yearName}`} className={"text-decoration-none text-black"}>
                                    <div className={"card-body row col col-11"}>
                                        <div className={'col col-6 d-flex justify-content-end'}>
                                            <h5 className={"card-title m-auto"}>{yearName}</h5>
                                        </div>
                                        <div className={'col col-6 d-flex justify-content-start'}>
                                            <h5 className={"card-title m-auto"}>{lang.body.moneyPage.main.balance}&nbsp;{balance}</h5>
                                        </div>
                                    </div>
                                </Link>
                                {/*<div className={'col col-12 d-flex justify-content-center mb-3'}>*/}
                                {/*    <h5 className={"card-title m-auto"}>{`Предстоящие расходы:`}&nbsp;{}</h5>*/}
                                {/*</div>*/}
                                <button className="carousel-control-prev text-dark" type="button">
                                    <i className={`fa-solid fa-chevron-left`}
                                       onClick={() => {
                                            window.location.replace(`/money?year=${preYear}`)
                                       }}/>
                                </button>
                                <button className="carousel-control-next text-dark" type="button">
                                    <i className={`fa-solid fa-chevron-right`}
                                       onClick={() => {
                                           window.location.replace(`/money?year=${nextYear}`)
                                       }}/>
                                </button>
                            </div>
                        </div>

                        {months.map((month, index) => {
                            const m = {
                                id: month,
                                name: lang.body.moneyPage[month],
                                income: dataMonths[index]['incomesMonthTotal'],
                                expense: dataMonths[index]['expensesMonthTotal'],
                                balance: dataMonths[index]['balanceMonth']
                            }
                            return (
                                <div className={"col-xs-4 col-sm-6 col-lg-4"} key={index}>
                                    <Link to={`/money/month/detail?year=${yearName}&month=${m.id}`} className={"text-decoration-none"}>
                                        <div className={"card mb-2"}>
                                            <div className={"card-body"}>
                                                <h5 className={"card-title border-bottom mb-3 pb-1"}>{m.name}</h5>
                                                <div className={"d-flex m-0"}>
                                                    <div className={"p-1 w-100"}>{lang.body.moneyPage.main.totalIncome}</div>
                                                    <div className={"p-1 flex-shrink-1"}>
                                                        <strong className={"m-0 text-success"}>{m.income}</strong>
                                                        <strong className={"m-0 text-success"}>&nbsp;{currency}</strong>
                                                    </div>
                                                </div>
                                                <div className={"d-flex m-0"}>
                                                    <div className={"p-1 w-100"}>{lang.body.moneyPage.main.totalExpense}</div>
                                                    <div className={"p-1 flex-shrink-1"}>
                                                        <strong className={"m-0 text-danger"}>{m.expense}</strong>
                                                        <strong className={"m-0 text-danger"}>&nbsp;{currency}</strong>
                                                    </div>
                                                </div>
                                                <div className={"d-flex m-0"}>
                                                    <div className={"p-1 w-100"}>{lang.body.moneyPage.main.balance}</div>
                                                    <div className={"p-1 flex-shrink-1"}>
                                                        <strong className={"m-0 text-success"}>{m.balance}</strong>
                                                        <strong className={"m-0 text-success"}>&nbsp;{currency}</strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            )
                        })}

                    </div>
                </div>
            </>
        )
    }
}
