import React, {useContext, useState} from 'react'
import {useHttp} from '../hooks/http.hook'
import {AuthContext} from '../context/AuthContext'
import {objectLang, setErrorWindow} from "../functions"
import {ModalWin} from "../elements/ModalWin"
import {LoaderClass} from "../elements/LoaderClass"
import {ModalCreateYearClass} from "../elements/ModalCreateYearClass"
import config from "../config.json"

export const CreateYearMoneyPage = () => {
    localStorage.setItem('page', 'CreateMoneyPage')
    const lang = objectLang().language
    const auth = useContext(AuthContext)
    const {loading, request} = useHttp()
    const [allowed, setAllowed] = useState(true)
    const [dataModal, setDataModal] = useState({})
    const [show, setShow] = useState(true)

    const currentYear = new Date(Date.now()).getFullYear()

    const data = {
        title: lang.body.moneyPage.createNewYear,
        desc: `${lang.body.moneyPage.currentYear}: ${currentYear}`,
        btnOKColor: 'success',
        btnCancelColor: 'secondary',
        btnOK: lang.body.moneyPage.btnCreate,
        btnCancel: lang.body.moneyPage.btnCancel
    }

    const closeHandler = () => {
        setShow(false)
    }

    const createNewYearHandler = async () => {
        const year = document.getElementById('create-year').value
        let host
        window.location.host === 'localhost:3000' ? host = config.localHost : host = config.heroku
        try {
            const data = await request(
                `${host}/api/money/year/add?year=${year}`,
                'POST',
                null,
                {
                    Authorization: `Bearer ${auth.accessToken} RefreshToken ${auth.refreshToken} DeviceId ${auth.deviceId}`
                })
            if (data.status > 201) {
                setAllowed(false)
                setDataModal(setErrorWindow('', data.code))
            } else {
                window.location.replace("/money/main")
            }

        } catch (e) {
            window.location.replace("/main")
        }
    }

    const cancelHandler = async () => {
        window.location.replace('/money/main')
    }

    if (loading) {
        return <LoaderClass/>
    }

    if (!allowed) {
        return (
            <ModalWin data={dataModal}/>
        )
    }

    return (
        <>
            {/*<MainMoneyPage/>*/}
            <ModalCreateYearClass
                show={show}
                data={data}
                currentYear={currentYear}
                createNewYearHandler={createNewYearHandler}
                cancelHandler={cancelHandler}
                closeHandler={closeHandler}
            />
        </>
    )
}