import React from 'react'
import {Link} from "react-router-dom"
import {MoneyNav} from "../elements/MoneyNav"

export class MainMoneyClass extends React.Component {

    render() {

        const {lang, money} = {
            lang: this.props.lang,
            money: this.props.money,
        }

        console.log(money)

        const currency = money['settings']['currencies'][0]

        const yearsHost = money['years']

        const years = []
        for (const yearName in yearsHost) {
            const incomesTotal = yearsHost[yearName]['incomesTotal']
            const expensesTotal = yearsHost[yearName]['expensesTotal']
            const preExpense = yearsHost[yearName]['preExpense']
            const balance = yearsHost[yearName]['balance']
            years.unshift({
                yearName: yearName,
                incomesTotal: incomesTotal['incomesYearTotal'],
                expensesTotal: expensesTotal['expensesYearTotal'],
                preExpense: preExpense,
                balance: balance,
            })
        }

        const dataNav = {
            pathBack: '/main',
            isMain: true,
            isSetting: true,
            isAddIncome: true,
            isAddCredit: true,
            isAddPerPayment: true,
            isAddPurchase: true
        }

        return (
            <>
                <div className={"mt-2 col col-sm-9 col-lg-12 col-xl-10 m-auto"}>
                    <MoneyNav data={dataNav}/>
                    <div className={"container m-auto"}>

                        {years.map((year, index) => {
                            const m = {
                                yearName: year['yearName'],
                                income: year['incomesTotal'],
                                expense: year['expensesTotal'],
                                preExpense: year['preExpense'],
                                balance: year['balance']
                            }
                            return (
                                <div className={"moneyMain m-auto"} key={index}>
                                {/*<div className={"moneyMain ms-2 me-2"} key={index}>*/}
                                    <Link to={`/money?year=${m.yearName}`} className={"text-decoration-none"}>
                                        <div className={"card mb-2"}>
                                            <div className={"card-body"}>
                                                <h5 className={"card-title border-bottom mb-3 pb-1"}>{m.yearName}</h5>
                                                <div className={"d-flex m-0"}>
                                                    <div className={"p-1 w-100"}>{lang.body.moneyPage.main.totalIncome}</div>
                                                    <div className={"p-1 flex-shrink-1"}>
                                                        <strong className={"m-0 text-success"}>{m.income}</strong>
                                                        <strong
                                                            className={"m-0 text-success"}>&nbsp;{currency}</strong>
                                                    </div>
                                                </div>
                                                <div className={"d-flex m-0"}>
                                                    <div className={"p-1 w-100"}>{lang.body.moneyPage.main.totalExpense}</div>
                                                    <div className={"p-1 flex-shrink-1"}>
                                                        <strong className={"m-0 text-danger"}>{m.expense}</strong>
                                                        <strong
                                                            className={"m-0 text-danger"}>&nbsp;{currency}</strong>
                                                    </div>
                                                </div>
                                                <div className={"d-flex m-0"}>
                                                    <div className={"p-1 w-100"}>{lang.body.moneyPage.main.UpcomingExpense}</div>
                                                    <div className={"p-1 flex-shrink-1"}>
                                                        <strong
                                                            className={"m-0 text-danger-emphasis"}>{m.preExpense}</strong>
                                                        <strong
                                                            className={"m-0 text-danger-emphasis"}>&nbsp;{currency}</strong>
                                                    </div>
                                                </div>
                                                <div className={"d-flex m-0"}>
                                                    <div className={"p-1 w-100"}>{lang.body.moneyPage.main.currentBalance}</div>
                                                    <div className={"p-1 flex-shrink-1"}>
                                                        <strong className={"m-0 text-success"}>{m.balance}</strong>
                                                        <strong
                                                            className={"m-0 text-success"}>&nbsp;{currency}</strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            )
                        })}

                    </div>
                </div>
            </>
        )
    }
}
