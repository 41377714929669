import React, {useCallback, useContext, useEffect, useState} from 'react'
import {useHttp} from '../hooks/http.hook'
import {AuthContext} from '../context/AuthContext'
import {checkName, objectLang, setErrorWindow} from "../functions"
import {LoaderClass} from "../elements/LoaderClass"
import config from "../config.json"
import {AddCashToClass} from "../elements/AddCashToClass"
import {ModalWin} from "../elements/ModalWin"

export const AddCashTo = (purpose) => {
    localStorage.setItem('page', 'AddCashTo')
    const lang = objectLang().language
    const auth = useContext(AuthContext)
    const {loading, request} = useHttp()
    const [allowed, setAllowed] = useState(true)
    const [dataModal, setDataModal] = useState({})
    const [show, setShow] = useState(true)
    const [cards, setCards] = useState([])
    const [oldNames, setOldNames] = useState([])

    let oldNamesArr = []

    const urlParams = new URLSearchParams(window.location.search)
    let urlType = 'purchase'
    if (urlParams.get('type')) {
        urlType = urlParams.get('type')
    }

    let incExp = lang.body.moneyPage.main.expenses, types = {income: false, expense: false, credit: false, perPayment: false, purchase: false}
    if (purpose.purpose === 'income') {
        types.income = true
        incExp = lang.body.moneyPage.main.income
    } else if (purpose.purpose === 'expense' && urlType === 'credit') {
        types.credit = true
        types.expense = true
        incExp = lang.body.moneyPage.main.credit
    } else if (purpose.purpose === 'expense' && urlType === 'per-payment') {
        types.perPayment = true
        types.expense = true
        incExp = lang.body.moneyPage.main.perPayment
    } else if (purpose.purpose === 'expense' && urlType === 'purchase') {
        types.purchase = true
        types.expense = true
        incExp = lang.body.moneyPage.main.purchase
    }

    const data = {
        title: `${lang.body.moneyPage.main.add} ${incExp}`,
        desc: lang.body.cardBankPage.descAdd,
        btnOKColor: 'success',
        btnCancelColor: 'secondary',
        btnOK: lang.body.cardBankPage.btnCreate,
        btnCancel: lang.body.cardBankPage.btnCancel,
        date: urlParams.get('date')
    }

    if (types.perPayment) {
        const arrDel = []
        for (const key in cards) {
            if (cards[key][4] === 'current-day') {
                arrDel.push(key)
            }
        }
        for (let i = 0; i < arrDel.length; i++) {
            cards.splice(i, 1)
        }
    }

    const getSettings = useCallback(async () => {
        let host
        window.location.host === 'localhost:3000' ? host = config.localHost : host = config.heroku
        try {
            const data = await request(
                `${host}/api/money/settings/get`,
                'GET',
                null,
                {
                    Authorization: `Bearer ${auth.accessToken} RefreshToken ${auth.refreshToken} DeviceId ${auth.deviceId}`
                })

            if (data.status > 200 && data.status !== 404) {
                setAllowed(false)
                setDataModal(setErrorWindow('', data.code, data.message, '', 'replaceTo /main'))
            }
            if (data.status === 200) {
                setCards(data.settings.cards)
                setOldNames(data.settings.names)
            }

        } catch (e) {
            console.error(e.message)
        }
    }, [request, auth])

    useEffect(() => {
        if (purpose.purpose === 'expense' && urlType === 'credit') {
            setDataModal(setErrorWindow(
                '',
                '',
                lang.body.moneyPage.main.expenseInactive,
                '',
                'replaceTo /money/main'
            ))
            setAllowed(false)
        }
        getSettings().then()
    }, [lang.body.moneyPage.main.expenseInactive, getSettings, purpose, urlType])

    const closeHandler = () => {
        setShow(false)
    }

    const changeNameHandler = (e) => {

        oldNamesArr = []
        const letter = e.target.value.toLowerCase()
        for (const i in oldNames) {
            if (oldNames[i].toLowerCase().includes(letter) && letter) {
                if (oldNamesArr.length < 5) {
                    oldNamesArr.push(oldNames[i])
                }
            }
        }

        const input = document.querySelector('#input-name')

        const elem_ul = document.querySelector('#elem-ul')
        if (elem_ul) {
            elem_ul.remove()
        }

        const ul = document.createElement('ul')
        ul.className = 'dropdown-menu'
        ul.id = 'elem-ul'
        if (oldNamesArr.length < 1) {
            ul.classList.remove('show')
        } else {
            ul.classList.add('show')
        }
        for (const i in oldNamesArr) {
            const e_a = document.createElement('a')
            const e_li = document.createElement('li')
            e_li.className = 'mt-1 text-decoration-point bg-light'
            e_a.innerHTML = oldNamesArr[i]
            e_a.className = 'dropdown-item'
            e_a.href = '#'
            e_a.onclick = () => {
                document.querySelector('#input-name').value = e_a.innerHTML
                document.querySelector('#elem-ul').remove()

            }
            e_li.append(e_a)
            ul.append(e_li)
        }
        input.after(ul)
    }

    const sendHandler = async (bodyObject) => {
        if (purpose.purpose === 'expense') {
            if (!bodyObject['cardId'] ||
                !bodyObject['name'] ||
                !bodyObject['means'] ||
                !bodyObject['date'] ||
                !bodyObject['value'] ||
                !bodyObject['currency'] ||
                !bodyObject['count']) {

                setAllowed(false)
                setDataModal(setErrorWindow(
                    '',
                    591,
                    '',
                    '',
                    `replaceTo /money/add-${purpose.purpose}?type=${urlParams.get('type')}`
                ))
                return
            }
        }
        if (purpose.purpose === 'income') {
            if (!bodyObject['name'] ||
                !bodyObject['date'] ||
                !bodyObject['value']) {

                setAllowed(false)
                setDataModal(setErrorWindow(
                    '',
                    591,
                    '',
                    '',
                    `replaceTo /money/add-${purpose.purpose}`
                ))
                return
            }
        }

        if (!checkName(bodyObject['name'])) {

            setAllowed(false)
            setDataModal(setErrorWindow(
                '',
                594,
                '',
                '',
                `replaceTo /money/add-${purpose.purpose}`
            ))
            return
        }

        let type = ''
        if (purpose.purpose === 'expense') {
            type = `?type=${urlParams.get('type')}`
        }
        let host
        window.location.host === 'localhost:3000' ? host = config.localHost : host = config.heroku
        try {
            const data = await request(
                `${host}/api/money/send/${purpose.purpose}${type}`,
                'POST',
                bodyObject,
                {
                    Authorization: `Bearer ${auth.accessToken} RefreshToken ${auth.refreshToken} DeviceId ${auth.deviceId}`
                })
            if (data.status > 201) {
                setAllowed(false)
                setDataModal(setErrorWindow('', data.code))
            } else {
                window.location.replace("/money/main")
            }

        } catch (e) {
            window.location.replace("/money/main")
        }
    }

    if (loading) {
        return <LoaderClass/>
    }

    if (!allowed) {
        return (
            <>
                <ModalWin data={dataModal}/>
            </>
        )
    }

    return (
        <>
            <AddCashToClass
                show={show}
                closeHandler={closeHandler}
                data={data}
                sendHandler={sendHandler}
                purpose={purpose}
                cards={cards}
                types={types}
                changeNameHandler={changeNameHandler}
            />
        </>
    )
}