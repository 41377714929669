import React from 'react'
import {Link} from "react-router-dom"
import {objectLang} from "../functions"

export class MoneySettingsClass extends React.Component {

    render() {

        const {data} = {
            data: this.props.data,
        }

        const lang = objectLang().language

        // console.log(data)

        const cardsDB = data['cards']
        const cards = []
        for (const i in cardsDB) {
            let currency = cardsDB[i][3], dateExp = cardsDB[i][4]
            if (!isNaN(Number(cardsDB[i][3]))) {
                currency = data['currencies'][Number(cardsDB[i][3])]
            }
            if (!isNaN(Number(cardsDB[i][4]))) {
                dateExp = String(new Date(Number(cardsDB[i][4])).getDate())
            } else {
                dateExp = 'В тот же день'
            }
            cards[i] = {
                cardId: cardsDB[i][0],
                name: cardsDB[i][1],
                type: cardsDB[i][2],
                currency: currency,
                dateExp: dateExp,
                defaultType: cardsDB[i][5],
            }
        }

        return (
            <>
                <div className={"mt-2 col col-sm-12 col-lg-12 col-xl-9 d-flex justify-content-center m-auto"}>
                    <div className={"row"}>

                        <div
                            className={'mb-2 col col-sm-11 col-lg-12 col-xl-12 d-flex flex-wrap justify-content-start'}>
                            <Link id={'back'}
                                  to={'/money/main'}
                                  type={'button'}
                                  className={'col btn btn-success m-1 fs-6 p-sm-1 p-2 d-flex align-items-center justify-content-center'}>
                                <i className="fa-solid fa-arrow-left"/>
                            </Link>
                            <Link id={'add-card'}
                                  to={'/money/add-card'}
                                  type={'button'}
                                  className={'col btn btn-success m-1 fs-6 p-sm-1 p-2 d-flex align-items-center justify-content-center'}>
                                {lang.body.moneyPage.main.addCard}
                            </Link>
                            <Link id={'add-bank'}
                                  to={'/money/add-bank'}
                                  type={'button'}
                                  className={'col btn btn-success m-1 fs-6 p-sm-1 p-2 d-flex align-items-center justify-content-center'}>
                                {lang.body.moneyPage.main.addBank}
                            </Link>
                            <Link id={'set-currency'}
                                  to={'/money/set-currency'}
                                  type={'button'}
                                  className={'col btn btn-success m-1 fs-6 p-sm-1 p-2 d-flex align-items-center justify-content-center'}>
                                {lang.body.moneyPage.main.setCurrency}
                            </Link>
                            <div className={'col d-lg-flex align-items-center justify-content-center d-none'}>
                            </div>
                            <div className={'col d-lg-flex align-items-center justify-content-center d-none'}>
                            </div>
                            <div className={'col d-lg-flex align-items-center justify-content-center d-none'}>
                            </div>
                        </div>

                        {cards.map((card, index) => {
                            const c = {
                                name: card['name'],
                                type: lang.body.moneyPage[`cardType${card['type']}`],
                                dateExp: card['dateExp'],
                                currency: card['currency'],
                                defaultType: card['defaultType'],
                                cardId: card['cardId']
                            }
                            if (c.name === 'Cash') {
                                c.name = lang.body.moneyPage.cardTypeCash
                            }

                            return (
                                <div className={"col-xs-6 col-sm-6 col-lg-6"} key={index}>
                                    <div className={"card mb-2"}>
                                        <div className={"card-body"}>
                                            <div className={'row border-bottom d-flex flex-nowrap'}>
                                                <h5 className={"card-title pb-1 w-75 me-3"}>{c.name}</h5>
                                                <Link to={`/money/card/update?cardId=${c.id}`}
                                                      className={'card-title d-flex justify-content-end align-items-center w-25 text-decoration-none text-primary'}>
                                                    {'Редактировать'}
                                                    {/*todo*/}
                                                </Link>
                                            </div>

                                            <div className={"d-flex m-0"}>
                                                <div className={"p-1 w-75"}>{'По умолчанию:'}</div>
                                                <div className={"p-1 w-50 flex-shrink-1"}>
                                                    <input type={'checkbox'}
                                                           className={"form-check-input"}
                                                           defaultChecked={c.defaultType}
                                                           disabled={true}/>
                                                </div>
                                            </div>
                                            <div className={"d-flex m-0"}>
                                                <div className={"p-1 w-75"}>Тип платежного средства:</div>
                                                <div className={"p-1 w-50 flex-shrink-1"}>
                                                    <strong className={"m-0 text-success"}>{c.type}</strong>
                                                </div>
                                            </div>
                                            <div className={"d-flex m-0"}>
                                                <div className={"p-1 w-75"}>Дата списания:</div>
                                                <div className={"p-1 w-50 flex-shrink-1"}>
                                                    <strong className={"m-0 text-success"}>{c.dateExp}</strong>
                                                </div>
                                            </div>
                                            <div className={"d-flex m-0"}>
                                                <div className={"p-1 w-75"}>Валюта карты:</div>
                                                <div className={"p-1 w-50 flex-shrink-1"}>
                                                    <strong className={"m-0 text-success"}>{c.currency}</strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </>
        )
    }
}
