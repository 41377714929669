import React from 'react'
import {Link} from "react-router-dom"
import {MoneyNav} from "../elements/MoneyNav"
import {hideHandler, filterMoneyHandler} from "../functions";

export class YearDetailClass extends React.Component {

    render() {

        const {lang, data} = {
            lang: this.props.lang,
            data: this.props.data,
        }

        const currency = data.currency
        const incomes = data.moneyYear['incomesOfNamesTotals']
        const expenses = data.moneyYear['expensesOfNamesTotals']
        const arrIncomes = [], arrIncomesCategories = [], arrExpenses = [], arrExpensesCategories = []

        if (incomes) {
            for (const inc in incomes) {
                const value = incomes[inc]
                arrIncomes.push([inc, value])
            }
        }
        const totalIncomeValue = data.moneyYear['incomesYearTotal']
        const totalExpensesValue = data.moneyYear['expensesYearTotal']
        const yearBalance = data.moneyYear['balance']

        if (expenses) {
            for (const exp in expenses) {
                const value = expenses[exp]
                arrExpenses.push([exp, value])
            }
        }

        for (const i in arrIncomes) {
            const category = arrIncomes[i]
            const nameCategory = category[0]
            const valueCategory = category[1]
            arrIncomesCategories.push([nameCategory, valueCategory])
        }

        for (const i in arrExpenses) {
            const category = arrExpenses[i]
            const nameCategory = category[0]
            const valueCategory = category[1]
            arrExpensesCategories.push([nameCategory, valueCategory])
        }

        const dataNav = {
            pathBack: `/money?year=${data['nameYear']}`,
            isMain: false,
            isMoney: true,
            isSetting: false,
            isAddIncome: true,
            isAddCredit: false,
            isAddPerPayment: false,
            isAddPurchase: true
        }

        return (
            <>
                <MoneyNav data={dataNav}/>
                <div className={"mt-3 col col-sm-11 col-lg-12 col-xl-9 m-auto"}>
                    <div className={"card"}>
                        <div className={"card-body"}>
                            <div className={'col col-12 d-flex justify-content-between mb-3'}>
                                <div className={'col col-6 d-flex justify-content-end'}>
                                    <h4 className={"card-title m-auto"}>{`${data.year}`}</h4>
                                </div>
                                <div className={'col col-5 d-flex justify-content-end'}>
                                    <h5 className={"card-title m-auto pe-2"}>{lang.body.moneyPage.main.balance}&nbsp;{yearBalance}</h5>
                                </div>
                                <Link to={localStorage.getItem('pathBack')}
                                      id={"button-close-year"}
                                      className={"col col-1 btn-close fs-3 m-0"}
                                      aria-label={"Close"}>
                                </Link>
                            </div>

                            <div className={`year-income-expense m-1`}>

                                {/* Годовые Доходы */}
                                <div className={'col border border-3 rounded-2 m-2 ps-2 pe-2 pt-3 pb-3'}>
                                    <div id={'div-incomes'} className={'col align-self-end'}>
                                        <div className={'filter mb-2'}>
                                            <h4 className={"card-title text-center m-1 mb-3 pb-1"}>{`Доходы:`}</h4>
                                            <input
                                                id={'incomes-filter'}
                                                className={'form-control'}
                                                placeholder={'Filter'}
                                                onChange={(event) => {
                                                    const resultArrNames = filterMoneyHandler(event, arrIncomesCategories, 'year')
                                                    hideHandler(resultArrNames, 'div-incomes', 'year')
                                                }}/>
                                        </div>

                                        {arrIncomesCategories.map((category, index) => {
                                            const nameCat = category[0]
                                            const value = category[1]
                                            if (nameCat !== 'total') {
                                                return (
                                                    <div id={`incomes-row-${index}`} className={'row m-1'} key={index}>
                                                        <div id={`incomes-div-${index}`} className={'col col-12 d-flex justify-content-between border-bottom'}>
                                                            <h6 id={`incomes-name-${index}`} className={'m-0'}>{nameCat}</h6>
                                                            <p id={`incomes-value-${index}`} className={'m-0'}>{`${value} (${currency})`}</p>
                                                        </div>
                                                    </div>
                                                )
                                            } else {
                                                return (<></>)
                                            }
                                        })}

                                    </div>

                                    <div id={'all-incomes-year'} className={'filter row m-1 mt-4'}>
                                        <div className={'col col-12 d-flex justify-content-between border-bottom'}>
                                            <h6 className={'m-0 text-success'}>{'Всего доходов в году'}</h6>
                                            <p className={'m-0'}>{`${totalIncomeValue} (${currency})`}</p>
                                        </div>
                                    </div>

                                </div>

                                {/* Годовые Расходы */}
                                <div className={'col border border-3 rounded-2 m-2 ps-2 pe-2 pt-3 pb-3'}>
                                    <div id={'div-expenses'} className={'add-expense ps-2 pe-2'}>
                                        <div className={'filter mb-2'}>
                                            <h4 className={"card-title text-center m-1 mb-3 pb-1"}>{`Расходы:`}</h4>
                                            <input
                                                id={'expenses-filter'}
                                                className={'form-control'}
                                                placeholder={'Filter'}
                                                onChange={(event) => {
                                                    const resultArrNames = filterMoneyHandler(event, arrExpensesCategories, 'year')
                                                    hideHandler(resultArrNames, 'div-expenses', 'year')
                                                }}/>
                                        </div>

                                        {arrExpensesCategories.map((category, index) => {
                                            const nameCat = category[0]
                                            const value = category[1]
                                            if (nameCat !== 'total') {
                                                return (
                                                    <div id={`expenses-row-${index}`} className={'row m-1'} key={index}>
                                                        <div id={`expenses-div-${index}`} className={'col col-12 d-flex justify-content-between border-bottom'}>
                                                            <h6 id={`expenses-name-${index}`} className={'m-0'}>{nameCat}</h6>
                                                            <p id={`expenses-value-${index}`} className={'m-0'}>{`${value} (${currency})`}</p>
                                                        </div>
                                                    </div>
                                                )
                                            } else {
                                                return (<></>)
                                            }
                                        })}

                                        <div id={'all-expenses-year'} className={'filter row m-1 mt-4'}>
                                            <div className={'col col-12 d-flex justify-content-between border-bottom'}>
                                                <h6 className={'m-0 text-danger'}>{'Всего расходов в году'}</h6>
                                                <p className={'m-0'}>{`${totalExpensesValue} (${currency})`}</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
