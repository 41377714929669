import {Link} from "react-router-dom"
import React from "react"
import {objectLang} from "../functions"

export const MoneyNav = ({data}) => {

    const lang = objectLang().language

    return (
        <>
            <div className={'container m-auto'}>
                <div className={'m-2 col d-flex flex-wrap justify-content-center justify-content-sm-center'}>
                    {data.isMain && <Link id={'to-main'}
                                          to={'/main'}
                                          type={'button'}
                                          className={'col col-lg-3 col-sm-4 col-12 btn m-1 fs-6 p-sm-1 p-2 d-flex align-items-center justify-content-center fw-bold'}>
                        {lang.navbar.toHome}
                    </Link>}
                    {data.isMoney && <Link id={'to-money'}
                                           to={data.pathBack}
                                           type={'button'}
                                           className={'col col-lg-3 col-sm-2 col-12 btn m-1 fs-6 p-sm-1 p-2 d-flex align-items-center justify-content-center fw-bold'}>
                        <i className={'fa-solid fa-arrow-left'}/>
                    </Link>}
                    {data.isSetting && <Link id={'settings'}
                                             to={'/money/settings'}
                                             type={'button'}
                                             className={'col col-lg-3 col-sm-4 col-12 btn m-1 fs-6 p-sm-1 p-2 d-flex align-items-center justify-content-center fw-bold'}>
                        {lang.body.moneyPage.main.settings}
                    </Link>}
                    {data.isAddIncome && <Link id={'add-income'}
                                               to={'/money/add-income'}
                                               type={'button'}
                                               className={'col col-lg-3 col-sm-4 col-12 btn btn-success m-1 fs-6 p-sm-1 p-2 d-flex align-items-center justify-content-center text-white fw-bold'}>
                        {lang.body.moneyPage.main.addIncome}
                    </Link>}
                    {data.isAddCredit && <Link id={'add-expense-credit'}
                                               to={'/money/add-expense?type=credit'}
                                               type={'button'}
                                               className={'col col-lg-3 col-sm-4 col-12 btn btn-info m-1 fs-6 p-sm-1 p-2 d-flex align-items-center justify-content-center text-dark fw-bold'}>
                        {lang.body.moneyPage.main.addCredit}
                    </Link>}
                    {data.isAddPerPayment && <Link id={'add-expense-per-payment'}
                                                   to={'/money/add-expense?type=per-payment'}
                                                   type={'button'}
                                                   className={'col col-lg-3 col-sm-6 col-12 btn btn-warning m-1 fs-6 p-sm-1 p-2 d-flex align-items-center justify-content-center text-dark fw-bold'}>
                        {lang.body.moneyPage.main.addPerPayment}
                    </Link>}
                    {data.isAddPurchase && <Link id={'add-expense-purchase'}
                                                 to={'/money/add-expense?type=purchase'}
                                                 type={'button'}
                                                 className={'col col-lg-3 col-sm-3 col-12 btn btn-warning m-1 fs-6 p-sm-1 p-2 d-flex align-items-center justify-content-center text-dark fw-bold'}>
                        {lang.body.moneyPage.main.addPurchase}
                    </Link>}
                </div>
            </div>
        </>
    )
}

