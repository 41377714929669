import React, {useCallback, useContext, useEffect, useState} from 'react'
import {AuthContext} from '../context/AuthContext'
import {objectLang, setErrorWindow} from "../functions"
import {createBrowserHistory} from "history"
import {useHttp} from "../hooks/http.hook"
import config from "../config.json"
import {PathLineClass} from "../elements/PathLineClass"
import {LoaderClass} from "../elements/LoaderClass"
import {MainPageAuthClass} from "../components/MainPageAuthClass"
import {MainPageNotAuthClass} from "../components/MainPageNotAuthClass"

export const MainPage = () => {
    localStorage.setItem('page', 'MainPage')
    localStorage.setItem('modal', JSON.stringify(setErrorWindow('', 540)))

    const history = createBrowserHistory()
    localStorage.setItem('pathBack', history.location.pathname)
    history.push('/')

    const auth = useContext(AuthContext)
    const {request, loading} = useHttp()
    const [languages, setLanguages] = useState(objectLang().languages)
    const [linkMyLinks, setLinkMyLinks] = useState('/my-links')
    const [isAuth, setIsAuth] = useState(false)

    const fetch = useCallback(async () => {
        let host
        window.location.host === 'localhost:3000' ? host = config.localHost : host = config.heroku
        try {
            const data = await request(
                `${host}/api/user/check`,
                'GET',
                null,
                {
                    Authorization: `Bearer ${auth.accessToken} RefreshToken ${auth.refreshToken} DeviceId ${auth.deviceId}`
                })

            if (data.code === 6041) {
                window.location.reload()
                return
            }

            if (data.code === 601) {
                setIsAuth(false)
            } else {
                setIsAuth(true)
            }

            const arrLang = data.languages.language
            let language = {}
            for (const key in arrLang) {
                language[arrLang[key].lang] = arrLang[key]
            }
            setLanguages(language)
            if (data.user) {
                setLinkMyLinks(data.user.rootFolder)
            }

        } catch (e) {

        }
    }, [auth, request, setLinkMyLinks])

    useEffect(() => {
        fetch().then()
    }, [fetch])

    const currentLang = objectLang().language.lang
    if (JSON.stringify(objectLang().languages) !== JSON.stringify(languages)) {
        localStorage.setItem('languages', JSON.stringify(languages))
        localStorage.setItem('language', JSON.stringify(languages[currentLang]))
    }

    const lang = objectLang().language
    const localStorageLang = lang.lang

    if (loading) {
        return <LoaderClass/>
    }

    if (isAuth) {
        if (auth.accessToken) {
            if (!localStorage.getItem('isChangedLang')) {
                if (auth.language !== localStorageLang) {
                    for (const i in languages) {
                        if (i === auth.language) {
                            localStorage.setItem('language', JSON.stringify(languages[i]))
                        }
                    }
                    localStorage.setItem('isChangedLang', 'true')
                    // window.location.reload()
                }
            }
        }
        return (
            <>
                <PathLineClass/>
                <MainPageAuthClass
                    lang={lang}
                    auth={auth}
                    currentLang={currentLang}
                    linkMyLinks={linkMyLinks}
                />
            </>
        )
    } else {
        return (
            <>
                <MainPageNotAuthClass
                    lang={lang}
                    currentLang={currentLang}
                />
            </>
        )
    }
}
