import React, {useCallback, useContext, useEffect, useState} from 'react'
import {AuthContext} from '../context/AuthContext'
import {useHttp} from "../hooks/http.hook"
import config from '../config.json'
import {ViewWindowSearch} from "./ViewWindowSearch"
import {NavbarClass} from "./NavbarClass"
import {set_select_by_value} from "../functions"

export const Navbar = ({documents = {}}) => {

    const auth = useContext(AuthContext)
    const {request} = useHttp()
    const [responseSearch, setResponseSearch] = useState(false)
    const [isChangedLang, setIsChangedLang] = useState(false)

    let avatarLink = auth.avatarLink

    const fetch = useCallback(async () => {
        let host
        window.location.host === 'localhost:3000' ? host = config.localHost : host = config.heroku
        try {
            const data = await request(
                `${host}/api/language/languages`,
                'GET')
            for (const i in data) {
                if (i === 'language') {
                    const lang = data[i]
                    let languages = {}
                    for (const j in lang) {
                        let language = 'en'
                        for (const k in lang) {
                            if (lang[k].lang === navigator.language.toLowerCase().split('-')[0]) {
                                language = navigator.language.toLowerCase().split('-')[0]
                            }
                        }
                        if (lang[j].lang === language) {
                            if (!localStorage.getItem('language')) {
                                localStorage.setItem('language', JSON.stringify(lang[j]))
                            }
                        }
                        languages[lang[j].lang] = lang[j]
                    }
                    localStorage.setItem('languages', JSON.stringify(languages))
                }
            }
        } catch (e) {
            window.location.replace("/main")
        }
    }, [request])

    useEffect(() => {
        if (!localStorage.getItem('languages')) {
            fetch().then()
        }
    }, [fetch])

    let currentLang = {}

    if (localStorage.getItem('language')) {
        currentLang = JSON.parse(localStorage.getItem('language'))
    } else {
        fetch().then(() => {
            window.location.reload()
        })
    }

    const changeSelect = (event) => {
        if (!localStorage.getItem('page')) {
            localStorage.setItem('page', 'MainPage')
        }

        localStorage.setItem('selectLang', event.target.value)
        const languages = JSON.parse(localStorage.getItem('languages'))
        const currLang = localStorage.getItem('selectLang')
        for (const i in languages) {
            if (i === currLang) {
                localStorage.setItem('language', JSON.stringify(languages[i]))
            }
        }
        if (auth.accessToken) {
            localStorage.setItem('isChangedLang', 'true')
        }

        set_select_by_value('language', event.target.value)

        setIsChangedLang(true)

        window.location.reload()
    }

    if (responseSearch) {
        return (
            <>
                {<Navbar documents={documents}/>}
                {<ViewWindowSearch lang={currentLang}/>}
            </>
        )
    }

    if (isChangedLang) {
        return (
            <>
                {<Navbar documents={documents}/>}
            </>
        )
    }

    return (
        <>
            {<NavbarClass
                auth={auth}
                avatarLink={avatarLink}
                currentLang={currentLang}
                setResponseSearch={setResponseSearch}
                changeSelect={changeSelect}
            />}
        </>
    )
}
