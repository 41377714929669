import React, {useCallback, useContext, useEffect, useState} from 'react'
import {useHttp} from '../hooks/http.hook'
import {AuthContext} from '../context/AuthContext'
import {Link} from "react-router-dom"
import {
    changeHandler,
    clickUpdateHandler,
    eyeHandler,
    objectLang,
    reformatDate,
    validationPassword,
    setErrorWindow
} from "../functions"
import config from '../config.json'
import {ModalWin} from "../elements/ModalWin"
import {LoaderClass} from "../elements/LoaderClass"

const $ = require("jquery")

export const ProfilePage = () => {
    localStorage.setItem('page', 'ProfilePage')
    const lang = objectLang().language
    const auth = useContext(AuthContext)
    const [user, setUser] = useState({})
    const {loading, request} = useHttp()
    const [allowedAuth, setAllowedAuth] = useState(true)
    const [allowedView, setAllowedView] = useState(true)
    const [descModal, setDescModal] = useState(lang.modal.errors.desc.isLife)
    const [titleModal, setTitleModal] = useState(lang.modal.errors.title)
    const [clickOKModal, setClickOKModal] = useState('reload')
    const [clickCancelModal, setClickCancelModal] = useState('reload')
    const [dataModalAuth, setDataModalAuth] = useState({})

    const fetch = useCallback(async () => {
        let host
        window.location.host === 'localhost:3000' ? host = config.localHost : host = config.heroku
        try {
            const data = await request(
                `${host}/api/user/profile/${auth.userId}`,
                'GET',
                null,
                {
                    Authorization: `Bearer ${auth.accessToken} RefreshToken ${auth.refreshToken} DeviceId ${auth.deviceId}`
                })
            if (data.status > 200) {
                setAllowedAuth(false)
                setDataModalAuth(setErrorWindow('', data.code))
            }

            if (data.status === 200) {
                setUser(data.user)
                // console.log(`Id: ${auth.userId}`)
            }

        } catch (e) {
            window.location.replace("/main")
        }
    }, [request, auth])

    useEffect(() => {
        fetch().then()
    }, [fetch])

    const lng = JSON.parse(localStorage.getItem('languages'))
    const languages = []
    for (const i in lng) {
        languages.push([i, lng[i].navbar.optionalsLanguage])
    }

    const saveHandler = async () => {
        let host
        window.location.host === 'localhost:3000' ? host = config.localHost : host = config.heroku

        const passOne = document.querySelector('#password-new-profile').value
        const passTwo = document.querySelector('#password-repeat-profile').value
        const validNewPasswords = validationPassword(passOne, passTwo, true)
        const newPassword = validNewPasswords.res
        const oldPassword = document.querySelector('#password-old-profile').value
        const validOldPassword = validationPassword(oldPassword)

        if (!oldPassword) {
            setDescModal(lang.messages.code_552)
            setClickOKModal('reload')
            setAllowedView(false)
            return
        }
        if (!validOldPassword.flag && oldPassword) {
            setDescModal(lang.messages[`code_${validOldPassword.code}`])
            setAllowedView(false)
            return
        }
        if (!validNewPasswords.flag && passOne && passTwo) {
            setDescModal(lang.messages[`code_${validNewPasswords.code}`])
            setAllowedView(false)
            return
        }

        try {
            const data = await request(`${host}/api/user/profile/update/${auth.userId}`, 'POST',
                {
                    firstName: $('#first-name-profile').val(),
                    lastName: $('#last-name-profile').val(),
                    nickName: $('#nick-name-profile').val(),
                    phone: $('#phone-number-profile').val(),
                    language: $('#language-profile').val(),
                    about: $('#about-me-profile').val(),
                    passwordOld: btoa(oldPassword),
                    passwordNew: btoa(newPassword)
                },
                {
                    Authorization: `Bearer ${auth.accessToken} RefreshToken ${auth.refreshToken} DeviceId ${auth.deviceId}`
                })
            if (data.status > 200) {
                setAllowedAuth(false)
                setDataModalAuth(setErrorWindow('', data.code))
            }
            if (passOne && passTwo) {
                setTitleModal(lang.modal.warnings.title)
                setDescModal(lang.messages.code_554)
                setClickOKModal('deleteCookies')
                setClickCancelModal('deleteCookies')
                setAllowedView(false)
                return
            }
            let userData = JSON.parse(localStorage.getItem('userData'))
            if (data.user.nickName !== userData.nickName) {
                userData.nickName = data.user.nickName
                localStorage.setItem('userData', JSON.stringify(userData))
            }
            if (data.user.language !== userData.language) {
                userData.language = data.user.language
                localStorage.setItem('userData', JSON.stringify(userData))
            }
            window.location.reload()

        } catch (e) {
            window.location.replace("/main")
        }
    }

    const deleteProfileHandler = () => {
        const lang = objectLang().language
        if (user.email === 'trial-user@mail.com') {
            setDescModal(lang.modal.errors.deleteTrialProfile)
            setClickOKModal('reload')
        } else {
            setDescModal(lang.modal.warnings.desc.deleteProfile)
            setClickOKModal('deleteCookies')
        }
        setAllowedView(false)
    }

    const dataModalView = {
        title: titleModal,
        desc: descModal,
        btnCancel: lang.modal.errors.btnCancel,
        btnOK: lang.modal.errors.btnOK,
        clickOK: clickOKModal,
        clickCancel: clickCancelModal
    }

    if (loading) {
        return <LoaderClass/>
    }

    if (!allowedAuth) {
        return (
            <ModalWin data={dataModalAuth}/>
        )
    }

    if (!allowedView) {
        return (
            <ModalWin data={dataModalView}/>
        )
    }

    return (
        <>
            <div className={"container rounded bg-white mt-md-3 pt-3 mb-5"}>
                <div className={"row"}>
                    <div className={"col col-md-4"}>
                        <Link to={'/upload-avatar'}>
                            <div className={"d-flex flex-column align-items-center mt-md-5"}>
                                <div style={{backgroundImage: `url(${auth.avatarLink})`}}
                                     className={"avatar-profile rounded-5 border border-light"}/>
                            </div>
                        </Link>
                    </div>
                    <div className={"col-sm-12 col-md-8 col-lg-6 col-xl-5"}>
                        <div className={"p-3"}>
                            <div className={"d-flex justify-content-between align-items-center"}>
                                <h4 id={"title-profile"}
                                    className={"font-weight-bold m-auto"}>{lang.body.profilePage.title}</h4>
                            </div>
                            <div className={"d-flex justify-content-between align-items-center mt-3 row"}>
                                <p id={"date-register-profile"}
                                   className={"text-right"}>{lang.body.profilePage.dateRegister}:&nbsp;
                                    <strong>{reformatDate(user.date)}</strong>
                                </p>
                            </div>
                            <div className={"row"}>
                                <div className={"row"}>
                                    <div className={"col-md-6"}>
                                        <label id={"label-first-name-profile"}
                                               className={"labels"}>{lang.body.profilePage.firstNameLabel}</label>
                                        <input id={"first-name-profile"}
                                               type={"text"}
                                               className={"form-control form-detail"}
                                               placeholder={lang.body.profilePage.firstNamePlaceholder}
                                               disabled
                                               onChange={changeHandler}
                                               defaultValue={user.firstName}>
                                        </input>
                                    </div>
                                    <div className={"col-md-6"}>
                                        <label id={"label-last-name-profile"}
                                               className={"labels"}>{lang.body.profilePage.lastNameLabel}</label>
                                        <input id={"last-name-profile"}
                                               type={"text"}
                                               className={"form-control form-detail"}
                                               defaultValue={user.lastName}
                                               disabled
                                               onChange={changeHandler}
                                               placeholder={lang.body.profilePage.lastNamePlaceholder}>
                                        </input>
                                    </div>
                                </div>

                                <div className={"row mt-3"}>
                                    <div className={"col-md-12"}>
                                        <label id={"label-nick-name-profile"}
                                               className={"labels"}>{lang.body.profilePage.nickNameLabel}</label>
                                        <input id={"nick-name-profile"}
                                               type={"text"}
                                               className={"form-control form-detail"}
                                               placeholder={lang.body.profilePage.nickNamePlaceholder}
                                               disabled
                                               onChange={changeHandler}
                                               defaultValue={user.nickName}>
                                        </input>
                                    </div>
                                    <div className={"col-md-12"}>
                                        <label id={"label-phone-number-profile"}
                                               className={"labels"}>{lang.body.profilePage.phoneNumberLabel}</label>
                                        <input id={"phone-number-profile"}
                                               type={"text"}
                                               className={"form-control form-detail"}
                                               placeholder={lang.body.profilePage.phoneNumberPlaceholder}
                                               disabled
                                               onChange={changeHandler}
                                               defaultValue={user.phone}>
                                        </input>
                                    </div>
                                    <div className={"col-md-12"}><label id={"label-email-profile"}
                                                                        className={"labels"}>{lang.body.profilePage.emailLabel}</label>
                                        <input id={"email-profile"}
                                               type={"text"}
                                               className={"form-control form-detail"}
                                               placeholder={lang.body.profilePage.emailPlaceholder}
                                               disabled
                                               onChange={changeHandler}
                                               defaultValue={user.email}>
                                        </input>
                                    </div>
                                    <div className={"col-md-12"}><label id={"label-language-profile"}
                                                                        className={"labels"}>{lang.body.profilePage.languageLabel}</label>
                                        <select className={'form-control form-select form-detail'}
                                                name={"language-profile"}
                                                id={"language-profile"}
                                                defaultValue={user.language}
                                                disabled
                                                onChange={changeHandler}>
                                            {languages.map((lang, index) => {
                                                    return (
                                                        <option value={lang[0]} key={index}>{lang[1][lang[0]]}</option>
                                                    )
                                                }
                                            )}
                                        </select>
                                    </div>
                                    <div className={"col-md-12"}><label id={"label-about-me-profile"}
                                                                        className={"labels"}>{lang.body.profilePage.aboutLabel}</label>
                                        <textarea id={"about-me-profile"}
                                                  rows={"3"}
                                                  className={"form-control form-detail"}
                                                  placeholder={lang.body.profilePage.aboutPlaceholder}
                                                  disabled
                                                  onChange={changeHandler}
                                                  defaultValue={user.about}>
                                        </textarea>
                                    </div>
                                </div>

                                {/*Passwords*/}
                                <div className={"row mt-3 d-none"} id={"divPasswords"}>
                                    <div className={"col-md-12"}>
                                        <label id={"label-password-old-profile"}
                                               className={"labels"}>{lang.body.profilePage.passwordOldLabel}</label>
                                        <div className={"input-group"}>
                                            <input id={"password-old-profile"}
                                                   type={"password"}
                                                   autoComplete={"old-password"}
                                                   className={"form-control form-detail"}
                                                   placeholder={lang.body.profilePage.passwordOldPlaceholder}
                                                   disabled
                                                   onChange={changeHandler}
                                                   defaultValue={""}>
                                            </input>
                                            <div className={"input-group-prepend"}>
                                                <div className={"div-eye input-group-text"}>
                                                    <Link to={``}
                                                          className={"eye-pass-profile-old"}
                                                          onClick={() => {
                                                              eyeHandler($('#eye-open-close-pass-old'), $('#password-old-profile'))
                                                          }}>
                                                        <i id={"eye-open-close-pass-old"}
                                                           className={"fa-regular fa-eye-slash"}/>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"col-md-12"}>
                                        <label id={"label-password-new-profile"}
                                               className={"labels"}>{lang.body.profilePage.passwordNewLabel}</label>
                                        <div className={"input-group"}>
                                            <input id={"password-new-profile"}
                                                   autoComplete={"new-password"}
                                                   type={"password"}
                                                   className={"form-control form-detail"}
                                                   defaultValue={""}
                                                   placeholder={lang.body.profilePage.passwordNewPlaceholder}
                                                   disabled
                                                   onChange={changeHandler}>
                                            </input>
                                            <div className={"input-group-prepend"}>
                                                <div className={"div-eye input-group-text"}>
                                                    <Link to={``}
                                                          className={"eye-pass-profile-new"}
                                                          onClick={() => {
                                                              eyeHandler($('#eye-open-close-pass-new'), $('#password-new-profile'))
                                                          }}>
                                                        <i id={"eye-open-close-pass-new"}
                                                           className={"fa-regular fa-eye-slash"}/>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"col-md-12"}>
                                        <label id={"label-password-repeat-profile"}
                                               className={"labels"}>{lang.body.profilePage.passwordRepeatLabel}</label>
                                        <div className={"input-group"}>
                                            <input id={"password-repeat-profile"}
                                                   autoComplete={"new-password"}
                                                   type={"password"}
                                                   className={"form-control form-detail"}
                                                   defaultValue={""}
                                                   placeholder={lang.body.profilePage.passwordRepeatPlaceholder}
                                                   disabled
                                                   onChange={changeHandler}>
                                            </input>
                                            <div className={"input-group-prepend"}>
                                                <div className={"div-eye input-group-text"}>
                                                    <Link to={``}
                                                          className={"eye-pass-profile-repeat"}
                                                          onClick={() => {
                                                              eyeHandler($('#eye-open-close-repeat'), $('#password-repeat-profile'))
                                                          }}>
                                                        <i id={"eye-open-close-repeat"}
                                                           className={"fa-regular fa-eye-slash"}/>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={"mt-2"}>
                                    <Link to={``}
                                          id={"link-update"}
                                          className={"text-decoration-none"}
                                          onClick={clickUpdateHandler}>
                                        {lang.body.profilePage.updateProfile}
                                    </Link>
                                </div>
                                {/*End Passwords*/}

                                <div className={"row mt-2 pe-4"}>
                                    <div className={"col-md-3 mt-2 pe-0"}>
                                        <Link to={localStorage.getItem('pathBack')}
                                              id={"btn-back"}
                                              className={"form-control form-detail btn btn-info text-white mt-sm-1"}>
                                            {`${lang.body.general.btnBack}`}
                                        </Link>
                                    </div>
                                    <div className={"col-md-4 mt-2 pe-0"}>
                                        <Link to={`/profile`}
                                              id={"btn-update"}
                                              className={"form-control form-detail btn btn-success mt-sm-1 disabled"}
                                              onClick={saveHandler}>
                                            {`${lang.body.profilePage.saveProfileBtn}`}
                                        </Link>
                                    </div>
                                    <div className={"col-md-5 mt-2 pe-0"}>
                                        <Link to={``}
                                              id={"btn-delete"}
                                              className={"form-control form-detail btn btn-danger mt-sm-1"}
                                              onClick={deleteProfileHandler}>
                                            {`${lang.body.profilePage.deleteAccountBtn}`}
                                        </Link>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
