import React, {useCallback, useContext, useEffect, useState} from 'react'
import {objectLang, setErrorWindow} from "../functions"
import {useHttp} from "../hooks/http.hook"
import config from "../config.json"
import {AuthContext} from "../context/AuthContext"
import {ModalWin} from "../elements/ModalWin"
import {LoaderClass} from "../elements/LoaderClass"

export const VerifyPage = () => {
    localStorage.setItem('page', 'VerifyPage')
    const urlParams = new URLSearchParams(window.location.search)
    const lang = objectLang().language
    const {loading, request} = useHttp()
    const [allowed, setAllowed] = useState(true)
    const [dataModal, setDataModal] = useState({})
    const [email, setEmail] = useState('')
    const [code, setCode] = useState('')
    const userId = urlParams.get('uid')
    const auth = useContext(AuthContext)

    const getUserEmail = useCallback(async () => {
        let host
        window.location.host === 'localhost:3000' ? host = config.localHost : host = config.heroku
        try {
            const data = await request(
                `${host}/api/user/getEmail/${userId}`,
                'GET',
                null
            )
            setEmail(data.email)
        } catch (e) {
            window.location.replace("/main")
        }
    }, [request, userId])

    useEffect(() => {
        getUserEmail().then()
    }, [getUserEmail])

    if (loading) {
        return <LoaderClass />
    }
    if (!allowed) {
        return (
            <>
                <ModalWin data={dataModal}/>
            </>

        )
    }

    const changeCodeHandler = (event) => {
        setCode(event.target.value.toString())
    }

    const sendCodeHandler = async () => {
        let host
        window.location.host === 'localhost:3000' ? host = config.localHost : host = config.heroku
        try {
            const data = await request(
                `${host}/api/user/verify`,
                'POST',
                {
                    email: btoa(email),
                    verifyCode: btoa(code)
                })
            if (data.status > 200) {
                setAllowed(false)
                setDataModal(setErrorWindow('', data.code))
            } else {
                auth.login(data.rootFolder, data.avatarLink, data.accessToken, data.refreshToken, data.userId, data.nickName, data.language)
                window.location.replace('/main')
            }

        } catch (e) {
            window.location.replace("/main")
        }
    }

    return (
        <>
            <div className={'container col-sm-9 col-xl-6 col-xxl-4'}>
                <div className={'rounded'}>
                    <fieldset className={'teal m-3 p-3'}>
                        <h1 id={"legend"} className={'fs-1'}>{lang.body.verifyPade.title}</h1>
                        <p id={"verify-description"} className={"m-0"}>{lang.body.verifyPade.description}</p>
                        <div id={"divVerify"} className={"mb-3"}>
                            <label
                                htmlFor={"verify"}
                                className={"form-label mt-3"}>
                                {lang.body.verifyPade.label}
                            </label>
                            <input
                                type={"text"}
                                name={"verify"}
                                id={"verify"}
                                className={"form-control"}
                                placeholder={lang.body.verifyPade.codePlaceholder}
                                onChange={changeCodeHandler}
                            />
                        </div>

                        <div className={"container d-flex justify-content-center"}>
                            <div className={"row"}>
                                <div className={"col-sm"}>
                                    <button
                                        id={"sendCode"}
                                        type={'button'}
                                        disabled={loading}
                                        className={"mt-2 btn btn-primary"}
                                        onClick={sendCodeHandler}>
                                        {lang.body.verifyPade.btnSendCode}
                                    </button>
                                    <button
                                        id={"btnBack"}
                                        type={'button'}
                                        disabled={loading}
                                        className={"ms-2 mt-2 btn btn-warning"}
                                        onClick={() => {
                                            return window.location.replace('/auth')
                                        }}>
                                        {lang.body.verifyPade.btnBack}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
            </div>
        </>
    )
}