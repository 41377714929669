import React, {useContext, useState} from 'react'
import {useHttp} from '../hooks/http.hook'
import {AuthContext} from '../context/AuthContext'
import config from '../config.json'
import {objectLang, removeCookieOfFolderId, setErrorWindow} from "../functions"
import {ModalWin} from "../elements/ModalWin"
import Container from "react-bootstrap/Container"
import {LoaderClass} from "../elements/LoaderClass"
import {PathLineClass} from "../elements/PathLineClass"
import {Row} from "react-bootstrap"
import {ViewWindowQuestion} from "../elements/ViewWindowQuestion"

export const CreateNotePage = ({newNoteObject = null}) => {
    localStorage.setItem('page', 'CreateNotePage')
    const $ = require("jquery")

    const auth = useContext(AuthContext)
    const {request, loading} = useHttp()
    const urlParams = new URLSearchParams(window.location.search)
    const folderId = urlParams.get('folderId')
    let lang = objectLang().language
    const [allowed, setAllowed] = useState(true)
    const [dataModal, setDataModal] = useState({})
    const [isQuestion, setIsQuestion] = useState(true)

    const clickSaveHandler = async () => {
        let host
        window.location.host === 'localhost:3000' ? host = config.localHost : host = config.heroku
        if (!$('#name').val()) {
            setAllowed(false)
            setDataModal(setErrorWindow(
                '',
                591,
                '',
                '',
                `replaceTo /create-note?folderId=${folderId}`
            ))
            return
        }
        try {
            const data = await request(
                `${host}/api/note/create`,
                'POST',
                {
                    userId: auth.userId,
                    parentFolder: folderId,
                    name: document.querySelector('#name').value,
                    body: document.querySelector('#body-note').value
                },
                {
                    Authorization: `Bearer ${auth.accessToken} RefreshToken ${auth.refreshToken} DeviceId ${auth.deviceId}`
                })

            if (data.status > 201) {
                setAllowed(false)
                setDataModal(setErrorWindow('', data.code))
            } else {
                removeCookieOfFolderId(folderId)
                window.location.replace(`/note/open/${data.note._id}`)
            }

        } catch (e) {
            window.location.replace("/main")
        }
    }

    const availableScreenHeight = Math.trunc(window.screen.availHeight * 0.5)
    const styleTextarea = {
        height: availableScreenHeight,
        background: "beige"
    }

    const closeNoteHandler = (newNote) => {
        localStorage.setItem('note-new-create', JSON.stringify(newNote))
        if (!newNote.name && !newNote.body) {
            window.location.replace(localStorage.getItem('pathBack'))
        } else if (!newNote.name && newNote.body) {
            setIsQuestion(false)
            setDataModal(setErrorWindow(
                lang.body.notePage.windowRequestIfSave.titleCreate,
                '',
                lang.body.notePage.windowRequestIfSave.descriptionCreate,
                lang.body.notePage.windowRequestIfSave.btnOKTapeName,
                'tapeNoteName',
                lang.body.notePage.windowRequestIfSave.btnCancelCreate,
                'replaceToBack',
                'success',
                'danger'))
        } else if ((newNote.name && newNote.body) || (newNote.name && !newNote.body)) {
            setIsQuestion(false)
            setDataModal(
                setErrorWindow(
                    lang.body.notePage.windowRequestIfSave.titleCreate,
                    '',
                    lang.body.notePage.windowRequestIfSave.descriptionCreate,
                    lang.body.notePage.windowRequestIfSave.btnOKCreate,
                    'saveNewNote',
                    lang.body.notePage.windowRequestIfSave.btnCancelCreate,
                    'replaceToBack',
                    'success',
                    'danger'))
        } else {
            window.location.replace(localStorage.getItem('pathBack'))
        }
    }

    if (loading) {
        return <LoaderClass/>
    }

    if (!allowed) {
        return (
            <>
                {<CreateNotePage newNoteObject={JSON.parse(localStorage.getItem('note-new-create'))}/>}
                {<ModalWin data={dataModal}/>}
            </>

        )
    }

    if (!isQuestion) {
        const newNoteObj = {
            userId: auth.userId,
            parentFolder: folderId,
            name: document.querySelector('#name').value,
            body: document.querySelector('#body-note').value
        }
        return (
            <>
                {<CreateNotePage newNoteObject={JSON.parse(localStorage.getItem('note-new-create'))}/>}
                {<ViewWindowQuestion newNote={newNoteObj} data={dataModal}/>}
            </>
        )
    }

    return (
        <>
            <PathLineClass documents={JSON.parse(localStorage.getItem('documents'))}/>
            <Container className={"mt-3 mt-lg-5 text-center"}>
                <div className={"d-flex flex-wrap justify-content-center card mb-3"}>
                    <div className={"card-header card-note text-success d-flex justify-content-start"}>
                        <Row xs={2} className={"d-flex flex-nowrap w-100"}>
                            <div style={{width: "96%"}}>
                                {!newNoteObject && <input
                                    placeholder={lang.body.createNotePage.placeholderName}
                                    type={"text"}
                                    className={"form-control fs-2 text-primary text-center"}
                                    id={"name"}
                                    style={{background: "beige"}}
                                />}
                                {newNoteObject && <input
                                    placeholder={lang.body.createNotePage.placeholderName}
                                    type={"text"}
                                    className={"form-control fs-2 text-primary text-center"}
                                    id={"name"}
                                    style={{background: "beige"}}
                                    defaultValue={newNoteObject.name}
                                />}
                            </div>
                            {/*<Link to={localStorage.getItem('pathBack')}*/}
                            {/*      id={"button-close-link"}*/}
                            {/*      className={"btn-close fs-2 p-2 m-0 me-1"}*/}
                            {/*      aria-label={"Close"}>*/}
                            {/*</Link>*/}
                            <button id={"button-close-note m-0"}
                                    type={"button"}
                                    className={"btn-close fs-2 mt-1"}
                                    aria-label={"Close"}
                                    onClick={() => {
                                        const newNoteObj = {
                                            userId: auth.userId,
                                            parentFolder: folderId,
                                            name: document.querySelector('#name').value,
                                            body: document.querySelector('#body-note').value
                                        }
                                        closeNoteHandler(newNoteObj)
                                    }}>
                            </button>
                        </Row>
                    </div>
                    <div className={"card-body card-note"}>
                        {!newNoteObject && <textarea
                            placeholder={lang.body.createNotePage.placeholderText}
                            className={"form-control fs-4 text-success"}
                            id={"body-note"}
                            style={styleTextarea}
                        />}
                        {newNoteObject && <textarea
                            placeholder={lang.body.createNotePage.placeholderText}
                            className={"form-control fs-4 text-success"}
                            id={"body-note"}
                            style={styleTextarea}
                            defaultValue={newNoteObject.body}
                        />}
                    </div>
                </div>
                <button
                    className={"col-4 col-md-3 btn btn-info border-0 me-2"}
                    type={"button"}
                    onClick={() => {
                        window.location.replace(localStorage.getItem('pathBack'))
                    }}>
                    {lang.body.general.btnBack}
                </button>
                <button
                    className={"col-6 col-md-4 btn btn-success border-0"}
                    type={"button"}
                    onClick={clickSaveHandler}>
                    {lang.body.createNotePage.btnSaveNote}
                </button>
            </Container>
        </>
    )
}
