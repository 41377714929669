import React from 'react'
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import {Link} from "react-router-dom"
import {FormSelect} from "react-bootstrap"
import {CalendarClass} from "../components/CalendarClass"
import {checkDot, formatDateToStr, objectLang} from "../functions"

export class AddCashToClass extends React.Component {

    render() {

        const {show, closeHandler, sendHandler, data, purpose, cards, types, changeNameHandler} = {
            show: this.props.show,
            closeHandler: this.props.closeHandler,
            sendHandler: this.props.sendHandler,
            data: this.props.data,
            purpose: this.props.purpose,
            cards: this.props.cards,
            types: this.props.types,
            changeNameHandler: this.props.changeNameHandler,
        }

        const lang = objectLang().language

        const year = new Date().getFullYear()
        let month = new Date().getMonth()
        let day = 1
        const hour = 6
        let dateCard = new Date(year, month, day, hour, 0, 0, 0)

        const viewCalendar = () => {
            const calendar = document.querySelector('.calendar')
            if (calendar.style.display === 'block') {
                calendar.style.display = 'none'
            } else {
                calendar.style.display = 'block'
            }
        }

        // let isCashDefault = true
        let divCountClassName = ''
        for (const i in cards) {
            // if (cards[i][5]) {
            //     isCashDefault = false
            // }
            if (cards[i][2] === 'Debit') {
                divCountClassName = 'd-none'
            }
            if (cards[i][2] === 'Credit') {
                divCountClassName = ''
            }
        }

        const changeSelectHandler = (e) => {
            const divCount = document.querySelector('#div-count')
            const value = e.target.value
            const children = e.target.children
            let currentCardType = 'cash'
            for (const child of children) {
                if (value === child.getAttribute('value')) {
                    localStorage.setItem('current-cardId-option', value)
                    localStorage.setItem('current-currency-option', child.getAttribute('currency'))
                    localStorage.setItem('current-means-option', child.getAttribute('means'))
                    day = Number(child.getAttribute('date'))
                    currentCardType = child.getAttribute('type')
                }
            }
            if (currentCardType === 'Credit') {
                divCount.classList.remove('d-none')
                dateCard = new Date(year, month + 1, day, hour, 0, 0, 0)
                document.querySelector('#input-date').value = formatDateToStr(dateCard)
            } else {
                divCount.classList.add('d-none')
                document.querySelector('#input-date').value = formatDateToStr(new Date())
            }
        }

        const sendToHost = () => {
            const localStorageType = localStorage.getItem('current-means-option')
            const localStorageCardId = localStorage.getItem('current-cardId-option')
            const localStorageCurrency = localStorage.getItem('current-currency-option')
            const cardId = localStorageCardId
            const name = document.querySelector('#input-name') ? document.querySelector('#input-name').value : ''
            const means = localStorageType ? localStorageType : 'cash'
            const date = document.querySelector('#input-date') ? document.querySelector('#input-date').value : ''
            const value = document.querySelector('#input-value') ? document.querySelector('#input-value').value : ''
            const currency = localStorageCurrency ? localStorageCurrency : 'USD'
            const count = document.querySelector('#input-count') ? document.querySelector('#input-count').value : '1'

            if (purpose.purpose === 'income') {
                sendHandler({name, date, value})
            }
            if (purpose.purpose === 'expense') {
                sendHandler({cardId, name, means, date, value, currency, count})
            }

            localStorage.removeItem('current-means-option')
            localStorage.removeItem('current-cardId-option')
            localStorage.removeItem('current-currency-option')
        }

        setTimeout(() => {
            if (document.querySelector('#select-type')) {
                const divCount = document.querySelector('#div-count')
                const selectedCard = document.querySelector('#select-type')
                for (const child of selectedCard.children) {
                    if (child.value === 'cash') {
                        child.textContent = lang.body.moneyPage.cash
                    }
                    if (child.value === localStorage.getItem('current-cardId-option')) {
                        child.selected = true
                        selectedCard.defaultValue = undefined
                        day = Number(child.getAttribute('date'))
                        if (isNaN(day)) {
                            day = new Date().getDate()
                            month = new Date().getMonth()
                        }
                        localStorage.setItem('current-currency-option', child.getAttribute('currency'))
                        localStorage.setItem('current-means-option', child.getAttribute('means'))
                        if (child.getAttribute('type') === 'Credit') {
                            divCount.classList.remove('d-none')
                            dateCard = new Date(year, month + 1, day, hour, 0, 0, 0)
                            document.querySelector('#input-date').value = formatDateToStr(dateCard)
                        } else {
                            divCount.classList.add('d-none')
                            document.querySelector('#input-date').value = formatDateToStr(new Date())
                        }
                    } else {
                        child.selected = false
                    }
                }

                dateCard = new Date(year, month, day, hour, 0, 0, 0)
                document.querySelector('#input-date').value = formatDateToStr(dateCard)
            }
        }, 500)

        let dateNumber = formatDateToStr()
        if (Number(data['date'])) {
            dateNumber = formatDateToStr(Number(data['date']))
        }

        return (
            <>
                <Modal id={"create-modal"} show={show}>
                    <Modal.Header>
                        <Modal.Title className={'w-100 text-center'}>{data.title}</Modal.Title>
                        <div className={'d-flex align-items-start'}>
                            <Link to={localStorage.getItem('moneyPathBack')}>
                                <Button className={'ms-2'} variant={"close"} onClick={closeHandler}/>
                            </Link>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <form className={'was-validated'}>
                            <fieldset className={'teal'}>
                                <CalendarClass/>
                                <label className={'form-label mt-3 mb-1'}>
                                    {lang.body.moneyPage.main.name}
                                </label>
                                <input id={'input-name'} className={'inputs-name form-control is-invalid'}
                                       placeholder={lang.body.moneyPage.main.name}
                                       onChange={changeNameHandler}
                                       defaultValue={''}
                                       required={true}
                                />
                                <label className={'form-label mt-3 mb-1'}>
                                    {lang.body.moneyPage.main.total}
                                </label>
                                <input id={'input-value'} className={'inputs-value form-control is-invalid'}
                                       placeholder={lang.body.moneyPage.main.tapeTotal}
                                       defaultValue={''}
                                       onChange={checkDot}
                                       required={true}
                                />
                                {types.expense && <label className={'form-label mt-3 mb-1'}>
                                    {lang.body.moneyPage.main.whatIsPayment}
                                </label>}
                                {types.expense &&
                                <FormSelect id={'select-type'}
                                            className={'inputs-type form-control is-invalid'}
                                            onChange={changeSelectHandler}
                                            defaultValue={'cash'}
                                            required={true}>
                                    {cards.map((card, index) => {

                                        if (card[5]) {
                                            localStorage.setItem('current-cardId-option', card[0])
                                        }
                                        return <option means={card[1]} type={card[2]} value={card[0]} key={index}
                                                       date={String(new Date(Number(card[4])).getDate())}
                                                       currency={card[3]}>{card[1]}</option>
                                    })}
                                </FormSelect>}
                                <label className={'form-label mt-3 mb-1'}>
                                    {lang.body.moneyPage.main.date}
                                </label>
                                <input id={'input-date'} className={'form-control is-invalid text-start'}
                                       onClick={viewCalendar}
                                       defaultValue={dateNumber}
                                       placeholder={lang.body.moneyPage.main.date}
                                       required={true}
                                />
                                <div id={'div-count'} className={divCountClassName}>
                                    {types.expense && <label className={'form-label mt-3 mb-1'}>
                                        {lang.body.moneyPage.main.numberOfPayment}
                                    </label>}
                                    {types.expense &&
                                    <input id={'input-count'} className={'inputs-counts form-control is-invalid'}
                                           type={'number'}
                                           defaultValue={'1'}
                                           placeholder={lang.body.moneyPage.main.numberOfPayment}
                                           required={true}
                                    />}
                                </div>
                            </fieldset>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant={data.btnOKColor}
                            onClick={sendToHost}>
                            {data.btnOK}
                        </Button>
                        <Link to={localStorage.getItem('moneyPathBack')}>
                            <Button variant={data.btnCancelColor}>
                                {data.btnCancel}
                            </Button>
                        </Link>

                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}