import React from 'react'
import {NavLink} from "react-router-dom"

export class MainPageNotAuthClass extends React.Component {

    render() {

        const {lang, currentLang} = {
            lang: this.props.lang,
            currentLang: this.props.currentLang,
        }

        const arrLinks = [
            {
                "name": lang.navbar.isNotAuth.btnAuth,
                "link": "/auth",
                "className": "Auth",
                "bodyClass": ""
            },
            {
                "name": lang.navbar.isNotAuth.btnRegister,
                "link": "/register",
                "className": "Register",
                "bodyClass": ""
            },
            {
                "name": lang.navbar.isAuth.btnPublicLinks,
                "link": "/folder/open-public/666a0310c1c139c28daa572b",
                "className": "PublicLinks",
                "bodyClass": ""
            }
        ]

        return (
            <>
                <div className={"d-flex justify-content-center flex-wrap"}>

                    <NavLink
                        to={`${arrLinks[0].link}`}
                        className={`card text-decoration-none m-3 col-10 col-lg-4 col-sm-3 ${arrLinks[0].className}`}>
                        <div className={"m-1 w-auto"}>
                            <div className={`card border-primary p-3 text-center ${arrLinks[0].bodyClass}`}>
                                <div className={"card-body text-success pt-1 ps-2 pe-2 pb-0"}>
                                    <h5 className={"card-title title-card"}><strong
                                        id={'main-page-button-' + 0}>{arrLinks[0].name}</strong>
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </NavLink>

                    <NavLink
                        to={`${arrLinks[1].link}`}
                        className={`card text-decoration-none m-3 col-10 col-lg-4 col-sm-3 ${arrLinks[1].className}`}>
                        <div className={"m-1 w-auto"}>
                            <div className={`card border-primary p-3 text-center ${arrLinks[1].bodyClass}`}>
                                <div className={"card-body text-success pt-1 ps-2 pe-2 pb-0"}>
                                    <h5 className={"card-title title-card"}><strong
                                        id={'main-page-button-' + 1}>{arrLinks[1].name}</strong>
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </NavLink>

                    {(currentLang === 'ru') && <NavLink
                        to={`${arrLinks[2].link}`}
                        className={`card text-decoration-none m-3 col-10 col-lg-4 col-sm-3 ${arrLinks[2].className}`}>
                        <div className={"m-1 w-auto"}>
                            <div className={`card border-primary p-3 text-center ${arrLinks[2].bodyClass}`}>
                                <div className={"card-body text-success pt-1 ps-2 pe-2 pb-0"}>
                                    <h5 className={"card-title title-card"}><strong
                                        id={'main-page-button-' + 2}>{arrLinks[2].name}</strong>
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </NavLink>}

                </div>
                <div className={'container mt-4'}>
                    <h5 className={'text-success text-start text-wrap ms-md-2 ps-3 pe-3 fs-5'}>
                        {lang.body.welcome}
                    </h5>
                </div>
            </>
        )
    }
}
